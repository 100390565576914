const BASE_URL = process.env.REACT_APP_GATEWAY_URL;

export const fetchPowerProfile = async data => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url = BASE_URL + '/apps/power-profile-generator';
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: new Headers({
        Authorization: bearer,
        'content-type': 'application/json',
      }),
      body: JSON.stringify(data),
    });
    const status = await response.status;
    if (status === 200) {
      const res = await response.json();
      return res;
    } else {
      return null;
    }
  } catch (e) {
    throw e;
  }
};
