import { all, takeLatest, put, call } from 'redux-saga/effects';
import {
  fetchAggregatedResult,
  fetchTimeSeries,
  fetchRawAggregation,
  fetchGeneralData,
} from './api';
import actions from './actions';

export function* requestGeneralData(params) {
  try {
    const data = yield call(
      fetchGeneralData,
      'clients_data',
      'file_names',
      params.id
    );
    yield put(actions.receiveGeneralData(data));
  } catch (e) {
    yield put(actions.receiveGeneralDataFailure(e));
  }
}

export function* requestSessionsPerChargingStation(params) {
  try {
    const data = yield call(
      fetchAggregatedResult,
      'evse',
      'sessions_per_evse',
      params.id
    );
    yield put(actions.receiveSessionsPerChargingStation(data));
  } catch (e) {
    yield put(actions.receiveSessionsPerChargingStationFailure(e));
  }
}

export function* requestEnergyPerChargingStation(params) {
  try {
    const data = yield call(
      fetchAggregatedResult,
      'evse',
      'energy_per_evse',
      params.id
    );
    yield put(actions.receiveEnergyPerChargingStation(data));
  } catch (e) {
    yield put(actions.receiveEnergyPerChargingStationFailure(e));
  }
}

export function* requestIdleRatioPerChargingStation(params) {
  try {
    const data = yield call(
      fetchAggregatedResult,
      'evse',
      'evse_occupancy_ratio',
      params.id
    );
    yield put(actions.receiveIdleRatioPerChargingStation(data));
  } catch (e) {
    yield put(actions.receiveIdleRatioPerChargingStationFailure(e));
  }
}

export function* requestFlexibilityPerChargingStation(params) {
  try {
    const data = yield call(
      fetchAggregatedResult,
      'evse',
      'flexi_distrib_per_evse',
      params.id
    );
    yield put(actions.receiveFlexibilityPerChargingStation(data));
  } catch (e) {
    yield put(actions.receiveFlexibilityPerChargingStationFailure(e));
  }
}

export function* requestSessionsPerUser(params) {
  try {
    const data = yield call(
      fetchAggregatedResult,
      'user',
      'nb_sessions_per_users',
      params.id
    );
    yield put(actions.receiveSessionsPerUser(data));
  } catch (e) {
    yield put(actions.receiveSessionsPerUserFailure(e));
  }
}

export function* requestHoursPluggedPerUser(params) {
  try {
    const data = yield call(
      fetchAggregatedResult,
      'user',
      'nb_hours_users_plugged',
      params.id
    );
    yield put(actions.receiveHoursPluggedPerUser(data));
  } catch (e) {
    yield put(actions.receiveHoursPluggedPerUserFailure(e));
  }
}

// export function* requestIdleRatioPerUser(params) {
//   try {
//     const data = yield call(
//       fetchAggregatedResult,
//       'user',
//       'users_idle_ratio',
//       params.id
//     );
//     yield put(actions.receiveIdleRatioPerUser(data));
//   } catch (e) {
//     console.log(e);
//   }
// }

export function* requestFlexibilityPerUser(params) {
  try {
    const data = yield call(
      fetchAggregatedResult,
      'user',
      'flexi_distrib_per_user',
      params.id
    );
    yield put(actions.receiveFlexibilityPerUser(data));
  } catch (e) {
    yield put(actions.receiveFlexibilityPerUserFailure(e));
  }
}

export function* requestHourlyConnectionsAcrossDay(params) {
  try {
    const data = yield call(
      fetchAggregatedResult,
      'evse',
      'nb_connection_hour_avg_data',
      params.id
    );
    yield put(actions.receiveHourlyConnectionsAcrossDay(data));
  } catch (e) {
    yield put(actions.receiveHourlyConnectionsAcrossDayFailure(e));
  }
}

export function* requestHourlyDisonnectionsAcrossDay(params) {
  try {
    const data = yield call(
      fetchAggregatedResult,
      'evse',
      'nb_disconnection_hour_avg_data',
      params.id
    );
    yield put(actions.receiveHourlyDisconnectionsAcrossDay(data));
  } catch (e) {
    yield put(actions.receiveHourlyDisconnectionsAcrossDayFailure(e));
  }
}

export function* requestHourlySessionsAcrossDay(params) {
  try {
    const data = yield call(
      fetchAggregatedResult,
      'evse',
      'simultaneous_sessions_across_the_day_avg',
      params.id
    );
    yield put(actions.receiveHourlySessionsAcrossDay(data));
  } catch (e) {
    yield put(actions.receiveHourlySessionsAcrossDayFailure(e));
  }
}

export function* requestPowerAcrossDay(params) {
  try {
    const data = yield call(
      fetchAggregatedResult,
      'evse',
      'power_accross_day_avg',
      params.id
    );
    yield put(actions.receivePowersAcrossDay(data));
  } catch (e) {
    yield put(actions.receivePowersAcrossDayFailure(e));
  }
}

export function* requestTotalEvTimeSeries(params) {
  try {
    const data = yield call(
      fetchAggregatedResult,
      'evse',
      'ts_evse_plugged',
      params.id
    );
    yield put(actions.receiveTotalEvTimeSeries(data));
  } catch (e) {
    yield put(actions.receiveTotalEvTimeSeriesFailure(e));
  }
}

export function* requestOptiPeakTimeSeries(params) {
  try {
    const data = yield call(
      fetchTimeSeries,
      'opti',
      'peak_time_series_w',
      params.id
    );
    yield put(actions.receiveOptiPeakTimeSeries(data));
  } catch (e) {
    yield put(actions.receiveOptiPeakTimeSeriesFailure(e));
  }
}

export function* requestOptiCo2TimeSeries(params) {
  try {
    const data = yield call(
      fetchTimeSeries,
      'opti',
      'co2_time_series_kg',
      params.id
    );
    yield put(actions.receiveOptiCo2TimeSeries(data));
  } catch (e) {
    yield put(actions.receiveOptiCo2TimeSeriesFailure(e));
  }
}

export function* requestOptiTouTimeSeries(params) {
  try {
    const data = yield call(
      fetchTimeSeries,
      'opti',
      'tou_time_series_local_currency',
      params.id
    );
    yield put(actions.receiveOptiTouTimeSeries(data));
  } catch (e) {
    yield put(actions.receiveOptiTouTimeSeriesFailure(e));
  }
}

export function* requestOptiTouPlusPeakWTimeSeries(params) {
  try {
    const data = yield call(
      fetchTimeSeries,
      'opti',
      'tou_plus_peak_time_series_w',
      params.id
    );
    yield put(actions.receiveOptiTouPlusPeakWTimeSeries(data));
  } catch (e) {
    yield put(actions.receiveOptiTouPlusPeakWTimeSeriesFailure(e));
  }
}

export function* requestOptiTouPlusPeakLCTimeSeries(params) {
  try {
    const data = yield call(
      fetchTimeSeries,
      'opti',
      'tou_plus_peak_time_series_local_currency',
      params.id
    );
    yield put(actions.receiveOptiTouPlusPeakLCTimeSeries(data));
  } catch (e) {
    yield put(actions.receiveOptiTouPlusPeakLCTimeSeriesFailure(e));
  }
}

export function* requestOptiPeakW(params) {
  try {
    const data = yield call(
      fetchRawAggregation,
      'opti',
      'peak_raw_w',
      params.id
    );
    yield put(actions.receiveOptiPeakW(data));
  } catch (e) {
    yield put(actions.receiveOptiPeakWFailure(e));
  }
}

export function* requestOptiCo2Kg(params) {
  try {
    const data = yield call(
      fetchRawAggregation,
      'opti',
      'co2_raw_kg',
      params.id
    );
    yield put(actions.receiveOptiCo2Kg(data));
  } catch (e) {
    yield put(actions.receiveOptiCo2KgFailure(e));
  }
}

export function* requestOptiTouLc(params) {
  try {
    const data = yield call(
      fetchRawAggregation,
      'opti',
      'tou_raw_local_currency',
      params.id
    );
    yield put(actions.receiveOptiTouLc(data));
  } catch (e) {
    yield put(actions.receiveOptiTouLcFailure(e));
  }
}

export function* requestOptiTouPeakW(params) {
  try {
    const data = yield call(
      fetchRawAggregation,
      'opti',
      'tou_plus_peak_raw_peak_w',
      params.id
    );
    yield put(actions.receiveOptiTouPeakW(data));
  } catch (e) {
    yield put(actions.receiveOptiTouPeakWFailure(e));
  }
}

export function* requestOptiTouPeakLc(params) {
  try {
    const data = yield call(
      fetchRawAggregation,
      'opti',
      'tou_raw_local_currency', // TODO fix this collection
      params.id
    );
    yield put(actions.receiveOptiTouPeakLc(data));
  } catch (e) {
    yield put(actions.receiveOptiTouPeakLcFailure(e));
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.REQUEST_GENERAL_DATA, requestGeneralData),
    yield takeLatest(
      actions.REQUEST_SESSIONS_PER_CHARGING_STATION,
      requestSessionsPerChargingStation
    ),
    yield takeLatest(
      actions.REQUEST_ENERGY_PER_CHARGING_STATION,
      requestEnergyPerChargingStation
    ),
    yield takeLatest(
      actions.REQUEST_IDLE_RATIO_PER_CHARGING_STATION,
      requestIdleRatioPerChargingStation
    ),
    yield takeLatest(
      actions.REQUEST_FLEXIBILITY_PER_CHARGING_STATION,
      requestFlexibilityPerChargingStation
    ),
    yield takeLatest(actions.REQUEST_SESSIONS_PER_USER, requestSessionsPerUser),
    yield takeLatest(
      actions.REQUEST_HOURS_PLUGGED_PER_USER,
      requestHoursPluggedPerUser
    ),
    // yield takeLatest(actions.REQUEST_HOURS_PLUGGED_PER_USER,requestIdleRatioPerUser),
    yield takeLatest(
      actions.REQUEST_FLEXIBILITY_PER_USER,
      requestFlexibilityPerUser
    ),
    yield takeLatest(
      actions.REQUEST_HOURLY_CONNECTIONS_ACROSS_DAY,
      requestHourlyConnectionsAcrossDay
    ),
    yield takeLatest(
      actions.REQUEST_HOURLY_DISCONNECTIONS_ACROSS_DAY,
      requestHourlyDisonnectionsAcrossDay
    ),
    yield takeLatest(
      actions.REQUEST_HOURLY_SESSIONS_ACROSS_DAY,
      requestHourlySessionsAcrossDay
    ),
    yield takeLatest(actions.REQUEST_POWER_ACROSS_DAY, requestPowerAcrossDay),
    yield takeLatest(
      actions.REQUEST_TOTAL_EV_TIME_SERIES,
      requestTotalEvTimeSeries
    ),
    yield takeLatest(
      actions.REQUEST_OPTI_PEAK_TIME_SERIES,
      requestOptiPeakTimeSeries
    ),
    yield takeLatest(
      actions.REQUEST_OPTI_CO2_TIME_SERIES,
      requestOptiCo2TimeSeries
    ),
    yield takeLatest(
      actions.REQUEST_OPTI_TOU_TIME_SERIES,
      requestOptiTouTimeSeries
    ),
    yield takeLatest(
      actions.REQUEST_OPTI_TOU_PLUS_PEAK_W_TIME_SERIES,
      requestOptiTouPlusPeakWTimeSeries
    ),
    yield takeLatest(
      actions.REQUEST_OPTI_TOU_PLUS_PEAK_LC_TIME_SERIES,
      requestOptiTouPlusPeakLCTimeSeries
    ),
    yield takeLatest(actions.REQUEST_OPTI_PEAK_W, requestOptiPeakW),
    yield takeLatest(actions.REQUEST_OPTI_CO2_KG, requestOptiCo2Kg),
    yield takeLatest(actions.REQUEST_OPTI_TOU_LC, requestOptiTouLc),
    yield takeLatest(actions.REQUEST_OPTI_TOU_PEAK_W, requestOptiTouPeakW),
    yield takeLatest(actions.REQUEST_OPTI_TOU_PEAK_LC, requestOptiTouPeakLc),
  ]);
}
