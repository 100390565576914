const actions = {
  RECEIVE_FLEET_SCHEDULE: 'RECEIVE_FLEET_SCHEDULE',
  RECEIVE_FLEET_SCHEDULE_SUCCESS: 'RECEIVE_FLEET_SCHEDULE_SUCCESS',
  RECEIVE_FLEET_SCHEDULE_FAILURE: 'RECEIVE_FLEET_SCHEDULE_FAILURE',
  SAVE_FLEET_SCHEDULE: 'SAVE_FLEET_SCHEDULE',
  SAVE_FLEET_SCHEDULE_SUCCESS: 'SAVE_FLEET_SCHEDULE_SUCCESS',
  SAVE_FLEET_SCHEDULE_FAILURE: 'SAVE_FLEET_SCHEDULE_FAILURE',
  RESET_FLEET_SCHEDULE: 'RESET_FLEET_SCHEDULE',

  receiveFleetSchedule: data => ({
    type: actions.RECEIVE_FLEET_SCHEDULE,
    payload: data,
  }),

  receiveFleetScheduleSuccess: data => ({
    type: actions.RECEIVE_FLEET_SCHEDULE_SUCCESS,
    payload: data,
  }),

  receiveFleetScheduleFailure: error => ({
    type: actions.RECEIVE_FLEET_SCHEDULE_FAILURE,
    error,
  }),

  saveFleetSchedule: data => ({
    type: actions.SAVE_FLEET_SCHEDULE,
    payload: data,
  }),

  saveFleetScheduleSuccess: () => ({
    type: actions.SAVE_FLEET_SCHEDULE_SUCCESS,
  }),

  saveFleetScheduleFailure: error => ({
    type: actions.SAVE_FLEET_SCHEDULE_FAILURE,
    error,
  }),

  resetFleetSchedule: () => ({
    type: actions.RESET_FLEET_SCHEDULE,
  }),
};
export default actions;
