import actions from './actions';

let initialFacilities;
const savedData = localStorage.getItem('selectedFacilities');
if (savedData === null) {
  initialFacilities = [];
} else {
  initialFacilities = JSON.parse(savedData);
}

const initState = {
  selectedFacilities: initialFacilities,
};

export default function reducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.SET_SELECTED_FACILITIES: {
      localStorage.setItem(
        'selectedFacilities',
        JSON.stringify(action.facilityUris)
      );
      return {
        ...state,
        selectedFacilities: action.facilityUris,
      };
    }

    default:
      return { ...state };
  }
}
