import actions from './actions';

const initState = {
  wallets: [],
  wallet: null,
  error: null,
};

export default function reducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.RECEIVE_WALLETS: {
      return {
        ...state,
        wallets: action.data,
      };
    }

    case actions.RECEIVE_WALLETS_FAILURE: {
      return {
        ...state,
        error: 'receiveWalletsFailure',
      };
    }

    case actions.RECEIVE_WALLET: {
      return {
        ...state,
        wallet: action.data,
      };
    }

    case actions.RECEIVE_WALLET_FAILURE: {
      return {
        ...state,
        error: 'receiveWalletFailure',
      };
    }

    case actions.RECEIVE_CREATE_WALLET: {
      return {
        ...state,
        wallet: action.data,
      };
    }

    case actions.RECEIVE_CREATE_WALLET_FAILURE: {
      return {
        ...state,
        error: 'receiveWalletCreateFailure',
      };
    }

    case actions.RECEIVE_UPDATE_WALLET: {
      return {
        ...state,
        wallet: action.data,
      };
    }

    case actions.RECEIVE_UPDATE_WALLET_FAILURE: {
      return {
        ...state,
        error: 'receiveWalletUpdateFailure',
      };
    }

    default:
      return state;
  }
}
