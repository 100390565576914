import defaultTheme from './default';
import logo from '../../assets/images/logos/total-logo.png';
import favicon from '../../assets/images/logos/total-favicon.ico';
import { ReactComponent as UserIcon } from '@iso/assets/images/icon/totalEnergies/person_red.svg';

const BRANDING_COLOR = '#ed0000';

const PRIMARY = [
  '#374649', // 0: Default
  'rgba(55, 70, 73, 0.30)', // 1: Darken 4%
  'rgba(55, 70, 73, 0.15)', // 2: Darken 5%
];
const INFO = [
  '#0063D2', // 0: Info
  'rgba(0, 99, 210, 0.30)', // 1: Info Medium
  'rgba(0, 99, 210, 0.15)', // 1: Info Light
];
const WARNING = [
  '#ffd13e', // 0: Warning
  'rgba(255, 209, 62, 0.30)', // 1: Warning Medium
  'rgba(255, 209, 62, 0.15)', // 2: Warning Light
];
const SUCCESS = [
  '#00B277', // 0: Success
  'rgba(0, 178, 119, 0.30)', // 1: Success Medium
  'rgba(0, 178, 119, 0.15)', // 2: Success Light
];
const ERROR = [
  '#FF6B4A', // 0: Error
  'rgba(255, 107, 74, 0.30)', // 1: Error Medium
  'rgba(255, 107, 74, 0.15)', // 2: Error Light
];
const GRAYSCALE = [
  '#374649', // 0: Black
  '#374649', // 1: Grey Darker
  '#374649', // 2: Grey Dark
  '#B7CBD3', // 3: Grey
  '#F7F9FA', // 4: Grey Light
  '#F7F9FA', // 5: Grey Lighter
  '#FFFFFF', // 6: Grey Lightest
  '#FFFFFF', // 7: White
];

const theme = {
  ...defaultTheme,
  logo,
  logoSize: 'auto 45px',
  favicon,
  palette: {
    primary: PRIMARY,
    info: INFO,
    warning: WARNING,
    success: SUCCESS,
    error: ERROR,
    grayscale: GRAYSCALE,
    text: [
      '#374649', // 0: Heading
      '#374649', // 1: HeadingLight
      '#374649', // 2: Text
      '#374649', // 3: TextDark
      '#374649', // 4: Heading Lighten 22%
      'rgba(55, 70, 73, 0.5)', // 5: Disabled
    ],
    sidebar: [
      '#fff', // 0 Background
      '#fff', // 1 LogoBackground
      '#e5e5e5', // 2 ItemSelected
      'transparent', // 3 ItemBorderColor
      '#374649', // 4 TextColor
      '#ed0000', // 5 TextColorHover
      '#e5e5e5', // 6 ItemHovered
    ],
    header: [
      '#fff', // 0 Background
      '#374649', // 1 TextColor
    ],
    footer: [
      '#fff', // 0 Background
      'transparent', // 1 BorderColor
      '#374649', // 2 TextColor
      '#ed0000', // 3 LinkColor
    ],
    table: [
      '#f8f8f8', // 0 TableHeadBgColor
      '#374649', // 1 TableHeadTextColor
      '#fff', // 2 BgColor
      'transparent', // 3 BorderColor
    ],
    card: [
      '#fff', // 0 CardBgColor
      'transparent', // 1 BorderColor
    ],
    input: [
      '#fff', // 0 BgColor
      'transparent', // 1 BorderColor
    ],
    select: [
      '#374649', // 0 Color
      '#ED0000', // 1 ColorHover
      '#ED0000', // 2 ColorSelected
      'transparent', // 0 Bg
      'transparent', // 1 BgHover
      '#e5e5e5', // 2 BgSelected
    ],
    button: [
      'transparent', // 0 BgColor
      '#ed0000', // 1 BorderColor
      '#ed0000', // 2 TextColor
    ],
    icon: [
      '#374649', // 0 Dark
      '#cfdff1', // 1 Light
    ],
    background: [
      '#f7f9fa', // 0 BackgroundColor
      'rgba(247, 249, 250, 0.02)', // 1 Light
    ],
    assetStatus: [
      'rgba(0, 99, 210, .15)', // 0 charging (blue)
      'rgba(255, 107, 74, .15)', // 1 Faulted (red)
      'rgba(0, 178, 119, .15)', // 2 available (green)
      'rgba(255, 209, 62, .15)', // 3 unreachable (yellow)
      'rgba(158, 158, 158, .15)', // 4 total (grey)
      'rgba(0, 99, 210, .3)', // 5 charging hover (blue)
      'rgba(255, 107, 74, .3)', // 6 Faulted hover (red)
      'rgba(0, 178, 119, .3)', // 7 available hover (green)
      'rgba(255, 209, 62, .3)', // 8 unreachable hover (yellow)
      'rgba(158, 158, 158, .3)', // 9 total hover (grey)
      'rgba(0, 99, 210, 1)', // 10 charging border (blue)
      'rgba(255, 107, 74, 1)', // 11 Faulted border (red)
      'rgba(0, 178, 119, 1)', // 12 available border (green)
      'rgba(255, 209, 62, 1)', // 13 unreachable border (yellow)
      'rgba(158, 158, 158, 1)', // 14 total border (grey)
    ],
    plots: [PRIMARY[0], '#ffd13e'],
    svgColor: ['white', BRANDING_COLOR],
  },
  fonts: {
    primary: "'Roboto', sans-serif",
    url: 'https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap',
  },
  userIcon: UserIcon,
};

export default theme;
