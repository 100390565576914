const actions = {
  REQUEST_CHARGING_SESSIONS: 'REQUEST_CHARGING_SESSIONS',
  RECEIVE_CHARGING_SESSIONS: 'RECEIVE_CHARGING_SESSIONS',

  REQUEST_CHARGING_SESSION: 'REQUEST_CHARGING_SESSION',
  RECEIVE_CHARGING_SESSION: 'RECEIVE_CHARGING_SESSION',

  RECEIVE_CHARGING_SESSIONS_FAILURE: 'RECEIVE_CHARGING_SESSIONS_FAILURE',
  RECEIVE_CHARGING_SESSION_FAILURE: 'RECEIVE_CHARGING_SESSION_FAILURE',

  GET_CHARGING_SESSION: 'GET_CHARGING_SESSION',
  GET_CHARGING_SESSION_SAGA: 'GET_CHARGING_SESSION_SAGA',

  GET_CHARGING_SESSIONS: 'GET_CHARGING_SESSIONS',
  GET_CHARGING_SESSIONS_SAGA: 'GET_CHARGING_SESSIONS_SAGA',

  UPDATE_CHARGING_SESSION: 'UPDATE_CHARGING_SESSION',
  UPDATE_CHARGING_SESSION_SAGA: 'UPDATE_CHARGING_SESSION_SAGA',

  SELECT_CURRENT_CHARGING_SESSION: 'SELECT_CURRENT_CHARGING_SESSION',
  TOGGLE_VIEW: 'CHARGING_SESSION_TOGGLE_VIEW',

  getChargingSessions: (chargingSessions, chargingSessionsFetchCallDone) => ({
    type: actions.GET_CHARGING_SESSIONS_SAGA,
  }),

  getChargingSession: (id, chargingSession, chargingSessionFetchCallDone) => ({
    type: actions.GET_CHARGING_SESSION_SAGA,
    id,
  }),

  requestChargingSessions: (entity, startDate, endDate) => ({
    type: actions.REQUEST_CHARGING_SESSIONS,
    entity,
    startDate,
    endDate,
  }),

  receiveChargingSessions: data => ({
    type: actions.RECEIVE_CHARGING_SESSIONS,
    data,
  }),

  receiveChargingSessionsFailure: error => ({
    type: actions.RECEIVE_CHARGING_SESSIONS_FAILURE,
    error,
  }),

  requestChargingSession: id => ({
    type: actions.REQUEST_CHARGING_SESSION,
    id,
  }),

  receiveChargingSession: data => ({
    type: actions.RECEIVE_CHARGING_SESSION,
    data,
  }),

  receiveChargingSessionsFailure: error => ({
    type: actions.RECEIVE_CHARGING_SESSION_FAILURE,
    error,
  }),
};
export default actions;
