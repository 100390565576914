const BASE_URL = process.env.REACT_APP_PAYMENTS_URL;

export const createEntity = async params => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url =
    BASE_URL +
    '/api/v1/stripe/' +
    params.stripePayments.type +
    '/' +
    params.stripePayments.customerId;

  try {
    let response = await fetch(url, {
      method: 'POST',
      headers: new Headers({
        Authorization: bearer,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(params.paymentOptions),
    });
    const data = await response.json();
    return data;
  } catch (e) {
    throw e;
  }
};

export const updateEntity = async params => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url = BASE_URL + '/api/v1/stripe/' + params.type;
  try {
    let response = await fetch(url, {
      method: 'PUT',
      headers: new Headers({
        Authorization: bearer,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(params.data),
    });
    const data = await response.json();
    return data;
  } catch (e) {
    throw e;
  }
};
