import { all, takeLatest, put, call } from 'redux-saga/effects';
import { fetchEntities, fetchEntity, createEntity } from './api';
import actions from './actions';

export function* requestPaymentOptions(params) {
  try {
    const data = yield call(
      fetchEntities,
      params.params.facilityId,
      params.params.customerId
    );
    yield put(actions.receivePaymentOptions(data));
  } catch (e) {
    yield put(actions.receivePaymentOptionsFailure, e);
  }
}

export function* requestPaymentOption(params) {
  try {
    const data = yield call(fetchEntity, params.id);
    yield put(actions.receivePaymentOption(data));
  } catch (e) {
    yield put(actions.receivePaymentOptionFailure, e);
  }
}

export function* requestCreatePaymentOption(params) {
  try {
    const data = yield call(createEntity, params.paymentOptions);
    yield put(actions.createPaymentOption(data));
  } catch (error) {
    yield put(actions.createPaymentOptionFailure, error);
  }
}
export function* requestUpdatePaymentOption(params) {
  try {
    const data = yield call(updateEntity, params);

    yield put(actions.updatePaymentOption(data));
  } catch (error) {
    yield put(actions.updatePaymentOptionFailure, error);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.REQUEST_PAYMENT_OPTIONS, requestPaymentOptions),
    yield takeLatest(actions.REQUEST_PAYMENT_OPTION, requestPaymentOption),
    yield takeLatest(
      actions.REQUEST_CREATE_PAYMENT_OPTION,
      requestCreatePaymentOption
    ),
    yield takeLatest(
      actions.RECEIVE_CREATE_PAYMENT_OPTION_FAILURE,
      requestCreatePaymentOption
    ),
    yield takeLatest(
      actions.REQUEST_UPDATE_PAYMENT_OPTION,
      requestUpdatePaymentOption
    ),
  ]);
}
