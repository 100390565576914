import actions from './actions';

const initState = {
  facilities: [],
  facility: null,
  error: null,
  requestFacilities: false,
};

export default function reducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.RECEIVE_FACILITIES: {
      return {
        ...state,
        facilities: action.data,
      };
    }

    case actions.RECEIVE_FACILITIES_FAILURE: {
      return {
        ...state,
        error: 'receiveFacilitiesFailure',
      };
    }

    case actions.RECEIVE_FACILITY: {
      return {
        ...state,
        facility: action.data,
      };
    }

    case actions.RECEIVE_FACILITY_FAILURE: {
      return {
        ...state,
        error: 'receiveFacilityFailure',
      };
    }

    case actions.SET_SELECTED_FACILITY: {
      return {
        ...state,
        selectedFacility: action.data,
      };
    }

    case actions.REQUEST_FACILITIES: {
      return {
        ...state,
        requestFacilities: true,
      };
    }

    case actions.REQUEST_FACILITIES_SUCCESS: {
      return {
        ...state,
        requestFacilities: false,
      };
    }

    default:
      return state;
  }
}
