import actions from './actions';

const initState = {
  evses: [],
  evse: null,
  error: null,
};

export default function reducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.RECEIVE_EVSES: {
      return {
        ...state,
        evses: action.data,
      };
    }

    case actions.RECEIVE_EVSES_FAILURE: {
      return {
        ...state,
        error: 'receiveEvsesFailure',
      };
    }

    case actions.RECEIVE_EVSE: {
      return {
        ...state,
        evse: action.data,
      };
    }

    case actions.RECEIVE_EVSE_FAILURE: {
      return {
        ...state,
        error: 'receiveEvseFailure',
      };
    }

    default:
      return state;
  }
}
