import actions from './actions';

const initState = {
  powerProfile: [],
  error: null,
  isLoading: false,
};

export default function reducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.RECEIVE_POWER_PROFILE: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case actions.RECEIVE_POWER_PROFILE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        powerProfile: action.payload,
      };
    }

    case actions.RECEIVE_POWER_PROFILE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: 'receivePowerProfileFailure',
      };
    }

    case actions.CLEAR_POWER_PROFILE: {
      return {
        ...state,
        isLoading: false,
        powerProfile: [],
      };
    }

    default:
      return state;
  }
}
