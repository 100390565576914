const BASE_URL = process.env.REACT_APP_PAYMENTS_URL;

export const fetchEntities = async companyId => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  let url = BASE_URL + '/api/v1/customer-account';
  if (companyId) {
    url += '/customer/' + companyId;
  }
  try {
    let response = await fetch(url, {
      method: 'get',
      headers: new Headers({
        Authorization: bearer,
        'Content-Type': 'application/json',
      }),
    });
    let data = await response.json();
    return data;
  } catch (e) {
    throw e;
  }
};

export const fetchEntity = async id => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url = BASE_URL + '/api/v1/customer-account/id/' + id;
  try {
    let response = await fetch(url, {
      method: 'get',
      headers: new Headers({
        Authorization: bearer,
        'Content-Type': 'application/json',
      }),
    });
    let data = await response.json();
    return data;
  } catch (e) {
    throw e;
  }
};

export const validateEntity = async (email, username) => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url =
    BASE_URL +
    '/api/customer-account/v1/validate?email=' +
    email +
    '&username=' +
    username;
  try {
    let response = await fetch(url, {
      method: 'get',
      headers: new Headers({
        Authorization: bearer,
        'Content-Type': 'application/json',
      }),
    });
    let data = await response.json();
    return data;
  } catch (e) {
    throw e;
  }
};

export const fetchEntityDetails = async (id, username) => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  let url = BASE_URL + '/api/v1/customer-account/details/';

  if (id) {
    url += 'id/' + id;
  } else {
    url += 'username/' + username;
  }

  try {
    let response = await fetch(url, {
      method: 'get',
      headers: new Headers({
        Authorization: bearer,
        'Content-Type': 'application/json',
      }),
    });
    let data = await response.json();
    return data;
  } catch (e) {
    throw e;
  }
};

export const createEntity = async params => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url = BASE_URL + '/api/v1/customer-account';
  try {
    let response = await fetch(url, {
      method: 'POST',
      headers: new Headers({
        Authorization: bearer,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(params),
    });
    const data = await response.json();
    return data;
  } catch (e) {
    throw e;
  }
};

export const updateEntity = async params => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url = BASE_URL + '/api/v1/customer-account';
  try {
    let response = await fetch(url, {
      method: 'PUT',
      headers: new Headers({
        Authorization: bearer,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(params.idTag),
    });
    const data = await response.json();
    return data;
  } catch (e) {
    throw e;
  }
};
