import { defaultRoles } from '@iso/components/Auth/roleFilter';
import {
  AiOutlineBarChart,
  AiOutlineEye,
  AiOutlineApi,
  AiOutlineSetting,
  AiOutlineDatabase,
  AiOutlineThunderbolt,
  AiOutlineIdcard,
  AiOutlineAppstoreAdd,
  AiOutlineAudit,
  AiOutlineCar,
} from 'react-icons/ai';

const options = [
  {
    key: 'analytics',
    label: 'sidebar.analytics',
    LeftIcon: AiOutlineBarChart,
    roles: [defaultRoles.ANALYTICS],
  },
  {
    key: 'forecast',
    label: 'sidebar.forecast',
    LeftIcon: AiOutlineEye,
    roles: [defaultRoles.FORECAST],
  },
  {
    key: 'optimization',
    label: 'sidebar.optimization',
    LeftIcon: AiOutlineSetting,
    roles: [defaultRoles.OPTIMIZATION],
  },
  {
    key: 'facilities',
    label: 'sidebar.facilities',
    LeftIcon: AiOutlineApi,
    roles: [defaultRoles.FACILITIES],
  },
  {
    key: 'realtime',
    label: 'sidebar.realtime',
    LeftIcon: AiOutlineThunderbolt,
    roles: [defaultRoles.REALTIME],
  },
  {
    key: 'fleets',
    label: 'sidebar.fleets',
    LeftIcon: AiOutlineCar,
    roles: [defaultRoles.FLEETS],
  },
  {
    key: 'idtags',
    label: 'sidebar.idTags',
    LeftIcon: AiOutlineIdcard,
    roles: [defaultRoles.IDTAG],
  },
  {
    key: 'reservations',
    label: 'sidebar.reservations',
    LeftIcon: AiOutlineAudit,
    roles: [defaultRoles.RESERVATIONS],
  },
  // {
  //   key: 'analyticsAndInsights',
  //   label: 'sidebar.analyticsAndInsights',
  //   LeftIcon: AiOutlineBook,
  //   role: defaultRoles.A4I,
  // },
  {
    key: 'assetsOnboarding',
    label: 'sidebar.assetsOnboarding',
    LeftIcon: AiOutlineAppstoreAdd,
    roles: [
      defaultRoles.ONBOARDING_FACILITY,
      defaultRoles.ONBOARDING_FLEET,
      defaultRoles.ONBOARDING_USER,
    ],
    items: [
      {
        key: 'facility',
        label: 'sidebar.assetsOnboarding.facility',
        role: defaultRoles.ONBOARDING_FACILITY,
      },
      {
        key: 'vehicle',
        label: 'sidebar.assetsOnboarding.vehicle',
        role: defaultRoles.ONBOARDING_FLEET,
      },
      {
        key: 'user',
        label: 'sidebar.assetsOnboarding.user',
        role: defaultRoles.ONBOARDING_USER,
      },
    ],
  },
  {
    key: 'biaAdmin',
    label: 'sidebar.biaAdmin',
    LeftIcon: AiOutlineDatabase,
    roles: [
      defaultRoles.BIA_ADMIN,
    ],
    items: [
      {
        key: 'reports',
        label: 'sidebar.biaAdmin.reports',
        role: defaultRoles.BIA_ADMIN,
      },
    ],
  },
];
export default options;
