import { all, takeLatest, put, call } from 'redux-saga/effects';
import { fetchChargingSessions, fetchEntity } from './api';
import actions from './actions';

export function* requestChargingSessions(params) {
  try {
    const data = yield call(
      fetchChargingSessions,
      params.entity,
      params.startDate,
      params.endDate
    );
    yield put(actions.receiveChargingSessions(data));
  } catch (e) {
    yield put(actions.receiveChargingSessionsFailure(e));
  }
}

export function* requestChargingSession(params) {
  try {
    const data = yield call(fetchEntity, params.id);
    yield put(actions.receiveChargingSession(data));
  } catch (e) {
    yield put(actions.receiveChargingSessionFailiure(e));
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(
      actions.REQUEST_CHARGING_SESSIONS,
      requestChargingSessions
    ),
    yield takeLatest(actions.REQUEST_CHARGING_SESSION, requestChargingSession),
  ]);
}
