const actions = {
  REQUEST_PAYMENTS: 'REQUEST_PAYMENTS',
  RECEIVE_PAYMENTS: 'RECEIVE_PAYMENTS',
  RECEIVE_PAYMENTS_FAILURE: 'RECEIVE_PAYMENTS_FAILURE',

  REQUEST_CREATE_PAYMENT: 'REQUEST_CREATE_PAYMENT',
  RECEIVE_CREATE_PAYMENT: 'RECEIVE_CREATE_PAYMENT',
  RECEIVE_CREATE_PAYMENT_FAILURE: 'RECEIVE_CREATE_PAYMENT_FAILURE',

  REQUEST_UPDATE_PAYMENT: 'REQUEST_UPDATE_PAYMENT',
  RECEIVE_UPDATE_PAYMENT: 'RECEIVE_UPDATE_PAYMENT',
  RECEIVE_UPDATE_PAYMENT_FAILURE: 'RECEIVE_UPDATE_PAYMENT_FAILURE',

  REQUEST_PAYMENT: 'REQUEST_PAYMENT',
  RECEIVE_PAYMENT: 'RECEIVE_PAYMENT',
  RECEIVE_PAYMENT_FAILURE: 'RECEIVE_PAYMENT_FAILURE',

  requestPayments: customerId => ({
    type: actions.REQUEST_PAYMENTS,
    customerId,
  }),

  receivePayments: data => ({
    type: actions.RECEIVE_PAYMENTS,
    data,
  }),

  receivePaymentsFailure: error => ({
    type: actions.RECEIVE_PAYMENTS_FAILURE,
    error,
  }),

  createPayment: payment => ({
    type: actions.REQUEST_CREATE_PAYMENT,
    payment,
  }),

  receiveCreatePayment: data => ({
    type: actions.RECEIVE_CREATE_PAYMENT,
    data,
  }),

  createPaymentFailure: error => ({
    type: actions.RECEIVE_CREATE_PAYMENT_FAILURE,
    error,
  }),

  updatePayment: payment => ({
    type: actions.REQUEST_UPDATE_PAYMENT,
    payment,
  }),

  receiveUpdatePayment: data => ({
    type: actions.RECEIVE_UPDATE_PAYMENT,
    data,
  }),

  updatePaymentFailure: error => ({
    type: actions.RECEIVE_UPDATE_PAYMENT_FAILURE,
    error,
  }),

  requestPayment: id => ({
    type: actions.REQUEST_PAYMENT,
    id,
  }),

  receivePayment: data => ({
    type: actions.RECEIVE_PAYMENT,
    data,
  }),

  receivePaymentFailure: error => ({
    type: actions.RECEIVE_PAYMENT_FAILURE,
    error,
  }),
};
export default actions;
