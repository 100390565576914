import { DEFAULT_BIA_DOMAIN } from '@iso/constants';
import { handleFetchError } from '@iso/lib/helpers/errorHandle';

const BASE_URL = process.env.REACT_APP_GATEWAY_URL;

export const fetchEntities = async (DOMAIN = DEFAULT_BIA_DOMAIN) => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url = `${BASE_URL}/api/facility?domain=${DOMAIN}`;

  try {
    let response = await fetch(url, {
      //credentials: 'include',
      method: 'get',
      headers: new Headers({
        Authorization: bearer,
        'Content-Type': 'application/json',
      }),
    });

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    }

    let data = await response.json();
    return data;
  } catch (e) {
    throw e;
  }
};

export const fetchEntity = async facilityUri => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url = `${BASE_URL}/api/facility/uri/${facilityUri}`;

  try {
    let response = await fetch(url, {
      method: 'get',
      headers: new Headers({
        Authorization: bearer,
        'content-type': 'application/json',
      }),
    });

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    }

    let data = await response.json();
    return data;
  } catch (e) {
    throw e;
  }
};

export const createFacility = async data => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url = `${BASE_URL}/api/facility`;
  const body = JSON.stringify(data);

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: new Headers({
        Authorization: bearer,
        'content-type': 'application/json',
      }),
      body,
    });

    let data = await response.json();

    handleFetchError(response, data);

    return data;
  } catch (e) {
    throw e;
  }
};

export const editFacility = async (facilityUri, data) => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url = `${BASE_URL}/api/facility/uri/${facilityUri}`;
  const body = JSON.stringify(data);

  try {
    const response = await fetch(url, {
      method: 'PUT',
      headers: new Headers({
        Authorization: bearer,
        'content-type': 'application/json',
      }),
      body,
    });

    let data = await response.json();

    handleFetchError(response, data);

    return data;
  } catch (e) {
    throw e;
  }
};

export const deleteFacility = async facilityUri => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url = `${BASE_URL}/api/facility/uri/${facilityUri}`;

  try {
    const response = await fetch(url, {
      method: 'DELETE',
      headers: new Headers({
        Authorization: bearer,
        'content-type': 'application/json',
      }),
    });

    if (response.status === 204) {
      return 'ok';
    }

    let data = await response.json();

    handleFetchError(response, data);
  } catch (e) {
    throw e;
  }
};
