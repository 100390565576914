import { all, takeLatest, put, call } from 'redux-saga/effects';
import { fetchUnlock, fetchReset } from './api';
import actions from './actions';

export function* requestUnlockConnector() {
  try {
    const data = yield call(fetchUnlock);
    yield put(actions.requestUnlockConnector(data));
  } catch (e) {}
}

export function* requestReset() {
  try {
    const data = yield call(fetchReset);
    yield put(actions.requestReset(data));
  } catch (e) {}
}

export function* requestRefresh() {
  try {
    yield put(actions.requestRefresh(data));
  } catch (e) {}
}

export function* requestSetpoint() {
  try {
    yield put(actions.requestSetpoint(data));
  } catch (e) {}
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.REQUEST_UNLOCK_CONNECTOR, requestUnlockConnector),
    yield takeLatest(actions.REQUEST_RESET, requestReset),
  ]);
}
