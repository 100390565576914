import antdES from 'antd/lib/locale/es_ES';
import saMessages from '../locales/es_ES.json';
import "dayjs/locale/es";

const saLang = {
  messages: {
    ...saMessages,
  },
  antd: antdES,
  locale: 'es-ES',
};
export default saLang;
