import { Select as AntSelect } from 'antd';
import styled from 'styled-components';

export const Select = styled(AntSelect)`
  &.ant-select {
    height: 100%;
    width: 250px;
  }

  & .ant-select-selector {
    border-radius: 8px !important;
    display: flex;
    align-items: center;
  }

  & .ant-select-selector input {
    height: 100% !important;
  }
`;
