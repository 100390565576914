import actions from './actions';

const initState = {
  currency: undefined,
};

export default function reducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.SET_CURRENCY: {
      return {
        ...state,
        currency: action.data,
      };
    }

    default:
      return state;
  }
}
