import { all, takeLatest, put, call } from 'redux-saga/effects';
import { fetchEntities, fetchEntity } from './api';
import actions from './actions';

export function* requestEvses(params) {
  try {
    const data = yield call(fetchEntities, params.facilityId);
    yield put(actions.receiveEvses(data));
  } catch (e) {
    yield put(actions.receiveEvsesFailure, e);
  }
}

export function* requestEvse(params) {
  try {
    const data = yield call(fetchEntity, params.uri);
    yield put(actions.receiveEvse(data));
  } catch (e) {
    yield put(actions.receiveEvseFailure, e);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.REQUEST_EVSES, requestEvses),
    yield takeLatest(actions.REQUEST_EVSE, requestEvse),
  ]);
}
