import { all, takeLatest, put, call } from 'redux-saga/effects';
import { fetchEntities, fetchEntity, createEntity, updateEntity } from './api';
import actions from './actions';

export function* requestWallets(params) {
  try {
    const data = yield call(fetchEntities, params.customerId);
    yield put(actions.receiveWallets(data));
  } catch (e) {
    yield put(actions.receiveWalletsFailure, e);
  }
}

export function* requestWallet(params) {
  try {
    const data = yield call(fetchEntity, params.customerId);
    yield put(actions.receiveWallet(data));
  } catch (e) {
    yield put(actions.receiveWalletFailure, e);
  }
}

export function* requestCreateWallet(params) {
  try {
    const data = yield call(createEntity, params);
    yield put(actions.createWallet(data));
  } catch (error) {
    yield put(actions.createWalletFailure, error);
  }
}
export function* requestUpdateWallet(params) {
  try {
    const data = yield call(updateEntity, params.username, params.money);

    yield put(actions.updateWallet(data));
  } catch (error) {
    yield put(actions.updateWalletFailure, error);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.REQUEST_WALLETS, requestWallets),
    yield takeLatest(actions.REQUEST_WALLET, requestWallet),
    yield takeLatest(actions.REQUEST_CREATE_WALLET, requestCreateWallet),
    yield takeLatest(actions.REQUEST_UPDATE_WALLET, requestUpdateWallet),
    yield takeLatest(
      actions.RECEIVE_UPDATE_WALLET_FAILURE,
      requestUpdateWallet
    ),
  ]);
}
