import { takeLatest, put, call } from 'redux-saga/effects';
import { fetchPowerProfile } from './api';
import actions from './actions';

export function* requestPowerProfile(action) {
  try {
    const data = yield call(fetchPowerProfile, action.payload);
    yield put(actions.receivePowerProfileSuccess(data));
  } catch (e) {
    yield put(actions.receivePowerProfileFailure(e));
  }
}

export default function* calculatorSaga() {
  yield takeLatest(actions.RECEIVE_POWER_PROFILE, requestPowerProfile);
}
