import { all } from 'redux-saga/effects';
import chargingSessionsSagas from '@iso/redux/chargingSessions/saga';
import facilitiesSagas from '@iso/redux/facilities/saga';
import evseSagas from '@iso/redux/evses/saga';
import a4iSagas from '@iso/redux/a4i/saga';
// import idTagSagas from '@iso/redux/idTags/saga';
import managerSagas from '@iso/redux/manager/saga';
import customerSagas from '@iso/redux/customers/saga';
import orderSagas from '@iso/redux/orders/saga';
import paymentSagas from '@iso/redux/payments/saga';
import paymentOptionsSagas from '@iso/redux/paymentOptions/saga';
import stripeSagas from '@iso/redux/stripe/saga';
import walletSagas from '@iso/redux/wallets/saga';
import calculatorSaga from '@iso/redux/calculator/saga';
import operationsSaga from '@iso/redux/operations/saga';

export default function* rootSaga(getState) {
  yield all([
    chargingSessionsSagas(),
    facilitiesSagas(),
    evseSagas(),
    a4iSagas(),
    // idTagSagas(),
    managerSagas(),
    customerSagas(),
    orderSagas(),
    paymentSagas(),
    paymentOptionsSagas(),
    stripeSagas(),
    walletSagas(),
    calculatorSaga(),
    operationsSaga(),
  ]);
}
