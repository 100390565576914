const BASE_URL = process.env.REACT_APP_PAYMENTS_URL;

export const fetchEntities = async (customerId, companyId) => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  let url = BASE_URL + '/api/v1/order';
  if (companyId) {
    url += '/companyId/' + companyId;
  }
  if (customerId != undefined && customerId != null) {
    url += '/customerId/' + customerId;
  }
  try {
    let response = await fetch(url, {
      method: 'get',
      headers: new Headers({
        Authorization: bearer,
        'Content-Type': 'application/json',
      }),
    });
    let data = await response.json();
    return data;
  } catch (e) {
    throw e;
  }
};

export const fetchEntity = async params => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url = BASE_URL + '/api/v1/order/id/' + params.orderId;
  try {
    let response = await fetch(url, {
      method: 'get',
      headers: new Headers({
        Authorization: bearer,
        'Content-Type': 'application/json',
      }),
    });
    let data = await response.json();
    return data;
  } catch (e) {
    throw e;
  }
};
