const actions = {
  CHANGE_DOMAIN: 'CHANGE_DOMAIN',
  changeDomain: domain => {
    return {
      type: actions.CHANGE_DOMAIN,
      payload: domain,
    };
  },
};
export default actions;
