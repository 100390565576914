import { all, takeLatest, put, call, take } from 'redux-saga/effects';
import {
  fetchEntities,
  fetchEntity,
  createEntity,
  updateEntity,
  fetchEntityDetails,
  validateEntity,
} from './api';
import actions from './actions';

export function* requestCustomers(params) {
  try {
    const data = yield call(fetchEntities, params.facilityId);
    yield put(actions.receiveCustomers(data));
  } catch (e) {
    yield put(actions.receiveCustomersFailure, e);
  }
}

export function* requestCustomer(params) {
  try {
    const data = yield call(fetchEntity, params.id);
    yield put(actions.receiveCustomer(data));
  } catch (e) {
    yield put(actions.receiveCustomerFailure, e);
  }
}

export function* requestCustomerValidation(params) {
  try {
    const data = yield call(validateEntity, params.email, params.username);
    yield put(actions.receiveValidateCustomer(data));
  } catch (e) {
    yield put(actions.receiveValidateCustomerFailure, e);
  }
}

export function* requestCustomerDetails(params) {
  try {
    const data = yield call(fetchEntityDetails, params.id, params.username);
    yield put(actions.receiveCustomerDetails(data));
  } catch (e) {
    yield put(actions.receiveCustomerDetailsFailure, e);
  }
}

export function* requestCreateCustomer(params) {
  try {
    const data = yield call(createEntity, params.customer);
    yield put(actions.createCustomer(data));
  } catch (error) {
    yield put(actions.createCustomerFailure, error);
  }
}
export function* requestUpdateCustomer(params) {
  try {
    const data = yield call(updateEntity, params);

    yield put(actions.updateCustomer(data));
  } catch (error) {
    yield put(actions.updateCustomerFailure, error);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.REQUEST_CUSTOMERS, requestCustomers),
    yield takeLatest(actions.REQUEST_CUSTOMER, requestCustomer),
    yield takeLatest(actions.REQUEST_CREATE_CUSTOMER, requestCreateCustomer),
    yield takeLatest(actions.REQUEST_CUSTOMER_DETAILS, requestCustomerDetails),
    yield takeLatest(
      actions.RECEIVE_CUSTOMER_DETAILS_FAILURE,
      requestCustomerDetails
    ),
    yield takeLatest(actions.RECEIVE_UPDATE_CUSTOMER, requestUpdateCustomer),
    yield takeLatest(actions.REQUEST_UPDATE_CUSTOMER, requestUpdateCustomer),
    yield take(actions.RECEIVE_VALIDATE_CUSTOMER, requestCustomerValidation),
    yield takeLatest(
      actions.RECEIVE_VALIDATE_CUSTOMER_FAILURE,
      requestCustomerValidation
    ),
  ]);
}
