import actions from './actions';

const initState = {
  selectedFleet: undefined,
  fleets: undefined,
  error: undefined,
};

export default function reducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.SET_FLEETS: {
      return {
        ...state,
        fleets: action.data,
      };
    }

    case actions.SET_SELECTED_FLEET: {
      return {
        ...state,
        selectedFleet: action.data,
      };
    }

    default:
      return state;
  }
}
