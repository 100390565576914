const BASE_URL = process.env.REACT_APP_PAYMENTS_URL;

export const fetchEntities = async (facilityId, customerId) => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  let url = BASE_URL + '/api/v1/payment-options';
  if (customerId) {
    url += '/customerId/' + customerId;
  }
  if (facilityId) {
    url += '/facilityId/' + facilityId;
  }
  try {
    let response = await fetch(url, {
      method: 'get',
      headers: new Headers({
        Authorization: bearer,
        'Content-Type': 'application/json',
      }),
    });
    let data = await response.json();
    return data;
  } catch (e) {
    throw e;
  }
};

export const fetchEntity = async id => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url = BASE_URL + '/api/v1/payment-options/id/' + id;
  try {
    let response = await fetch(url, {
      method: 'get',
      headers: new Headers({
        Authorization: bearer,
        'Content-Type': 'application/json',
      }),
    });
    let data = await response.json();
    return data;
  } catch (e) {
    throw e;
  }
};

export const createEntity = async params => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url = BASE_URL + '/api/v1/payment-options';

  if (params.paymentOptions && params.paymentOptions.customerId) {
    url = '/customerId/' + params.paymentOptions.customerId;
  }
  if (params.paymentOptions && params.paymentOptions.facilityId) {
    url = '/facilityId/' + params.paymentOptions.facilityId;
  }
  try {
    let response = await fetch(url, {
      method: 'POST',
      headers: new Headers({
        Authorization: bearer,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(params.paymentOptions),
    });
    const data = await response.json();
    return data;
  } catch (e) {
    throw e;
  }
};

export const updateEntity = async params => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url = BASE_URL + '/api/v1/payment-options';
  try {
    let response = await fetch(url, {
      method: 'PUT',
      headers: new Headers({
        Authorization: bearer,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(params.idTag),
    });
    const data = await response.json();
    return data;
  } catch (e) {
    throw e;
  }
};
