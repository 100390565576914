const actions = {
  REQUEST_EVSES: 'REQUEST_EVSES',
  RECEIVE_EVSES: 'RECEIVE_EVSES',
  RECEIVE_EVSES_FAILURE: 'RECEIVE_EVSES_FAILURE',

  REQUEST_EVSE: 'REQUEST_EVSE',
  RECEIVE_EVSE: 'RECEIVE_EVSE',
  RECEIVE_EVSE_FAILURE: 'RECEIVE_EVSE_FAILURE',

  requestEvses: facilityId => ({
    type: actions.REQUEST_EVSES,
    facilityId,
  }),

  receiveEvses: data => ({
    type: actions.RECEIVE_EVSES,
    data,
  }),

  receiveEvsesFailure: error => ({
    type: actions.RECEIVE_EVSES_FAILURE,
    error,
  }),

  requestEvse: id => ({
    type: actions.REQUEST_EVSE,
    id,
  }),

  receiveEvse: data => ({
    type: actions.RECEIVE_EVSE,
    data,
  }),

  receiveEvseFailure: error => ({
    type: actions.RECEIVE_EVSE_FAILURE,
    error,
  }),
};
export default actions;
