const actions = {
  REQUEST_CUSTOMERS: 'REQUEST_CUSTOMERS',
  RECEIVE_CUSTOMERS: 'RECEIVE_CUSTOMERS',
  RECEIVE_CUSTOMERS_FAILURE: 'RECEIVE_CUSTOMERS_FAILURE',

  REQUEST_CREATE_CUSTOMER: 'REQUEST_CREATE_CUSTOMER',
  CREATE_CUSTOMER: 'CREATE_CUSTOMER',
  CREATE_CUSTOMER_FAILURE: 'CREATE_CUSTOMER_FAILURE',

  REQUEST_UPDATE_CUSTOMER: 'REQUEST_UPDATE_CUSTOMER',
  RECEIVE_UPDATE_CUSTOMER: 'RECEIVE_UPDATE_CUSTOMER',
  RECEIVE_UPDATE_CUSTOMER_FAILURE: 'RECEIVE_UPDATE_CUSTOMER_FAILURE',

  REQUEST_CUSTOMER: 'REQUEST_CUSTOMER',
  RECEIVE_CUSTOMER: 'RECEIVE_CUSTOMER',
  RECEIVE_CUSTOMER_FAILURE: 'RECEIVE_CUSTOMER_FAILURE',

  REQUEST_CUSTOMER_DETAILS: 'REQUEST_CUSTOMER_DETAILS',
  RECEIVE_CUSTOMER_DETAILS: 'RECEIVE_CUSTOMER_DETAILS',
  RECEIVE_CUSTOMER_DETAILS_FAILURE: 'RECEIVE_CUSTOMER_DETAILS_FAILURE',

  REQUEST_VALIDATE_CUSTOMER: 'REQUEST_VALIDATE_CUSTOMER',
  RECEIVE_VALIDATE_CUSTOMER: 'RECEIVE_VALIDATE_CUSTOMER',
  RECEIVE_VALIDATE_CUSTOMER_FAILURE: 'RECEIVE_VALIDATE_CUSTOMER_FAILURE',

  requestCustomers: facilityId => ({
    type: actions.REQUEST_CUSTOMERS,
    facilityId,
  }),

  receiveCustomers: data => ({
    type: actions.RECEIVE_CUSTOMERS,
    data,
  }),

  receiveCustomersFailure: error => ({
    type: actions.RECEIVE_CUSTOMERS_FAILURE,
    error,
  }),

  requestCreateCustomer: customer => ({
    type: actions.REQUEST_CREATE_CUSTOMER,
    customer,
  }),

  createCustomer: data => ({
    type: actions.CREATE_CUSTOMER,
    data,
  }),

  createCustomerFailure: error => ({
    type: actions.CREATE_CUSTOMER_FAILURE,
    error,
  }),

  updateCustomer: customer => ({
    type: actions.REQUEST_UPDATE_CUSTOMER,
    customer,
  }),

  receiveUpdateCustomer: data => ({
    type: actions.RECEIVE_UPDATE_CUSTOMER,
    data,
  }),

  updateCustomerFailure: error => ({
    type: actions.RECEIVE_UPDATE_CUSTOMER_FAILURE,
    error,
  }),

  requestCustomer: id => ({
    type: actions.REQUEST_CUSTOMER,
    id,
  }),

  receiveCustomer: data => ({
    type: actions.RECEIVE_CUSTOMER,
    data,
  }),

  receiveCustomerFailure: error => ({
    type: actions.RECEIVE_CUSTOMER_FAILURE,
    error,
  }),

  requestCustomerDetails: (id, username) => ({
    type: actions.REQUEST_CUSTOMER_DETAILS,
    id,
    username,
  }),

  receiveCustomerDetails: data => ({
    type: actions.RECEIVE_CUSTOMER_DETAILS,
    data,
  }),

  receiveCustomerDetailsFailure: error => ({
    type: actions.RECEIVE_CUSTOMER_DETAILS_FAILURE,
    error,
  }),

  requestValidateCustomer: (email, username) => ({
    type: actions.REQUEST_VALIDATE_CUSTOMER,
    email,
    username,
  }),

  receiveValidateCustomer: data => ({
    type: actions.RECEIVE_VALIDATE_CUSTOMER,
    data,
  }),

  receiveValidateCustomerFailure: error => ({
    type: actions.RECEIVE_VALIDATE_CUSTOMER_FAILURE,
    error,
  }),
};
export default actions;
