const actions = {
  REQUEST_CREATE_STRIPE_CUSTOMER: 'REQUEST_CREATE_STRIPE_CUSTOMER',
  RECEIVE_CREATE_STRIPE_CUSTOMER: 'RECEIVE_CREATE_STRIPE_CUSTOMER',
  RECEIVE_CREATE_STRIPE_CUSTOMER_FAILURE:
    'RECEIVE_CREATE_STRIPE_CUSTOMER_FAILURE',

  REQUEST_UPDATE_STRIPE_CUSTOMER: 'REQUEST_UPDATE_STRIPE_CUSTOMER',
  RECEIVE_UPDATE_STRIPE_CUSTOMER: 'RECEIVE_UPDATE_STRIPE_CUSTOMER',
  RECEIVE_UPDATE_STRIPE_CUSTOMER_FAILURE:
    'RECEIVE_UPDATE_STRIPE_CUSTOMER_FAILURE',

  REQUEST_CREATE_STRIPE_PAYMENT: 'REQUEST_CREATE_STRIPE_PAYMENT',
  RECEIVE_CREATE_STRIPE_PAYMENT: 'RECEIVE_CREATE_STRIPE_PAYMENT',
  RECEIVE_CREATE_STRIPE_PAYMENT_FAILURE:
    'RECEIVE_CREATE_STRIPE_PAYMENT_FAILURE',

  REQUEST_UPDATE_STRIPE_PAYMENT: 'REQUEST_UPDATE_STRIPE_PAYMENT',
  RECEIVE_UPDATE_STRIPE_PAYMENT: 'RECEIVE_UPDATE_STRIPE_PAYMENT',
  RECEIVE_UPDATE_STRIPE_PAYMENT_FAILURE:
    'RECEIVE_UPDATE_STRIPE_PAYMENTS_FAILURE',

  createStripePayment: stripePayments => ({
    type: actions.REQUEST_CREATE_STRIPE_PAYMENT,
    stripePayments,
  }),

  receiveCreateStripePayment: data => ({
    type: actions.RECEIVE_CREATE_STRIPE_PAYMENT,
    data,
  }),

  createStripeCPaymentFailure: error => ({
    type: actions.RECEIVE_CREATE_STRIPE_PAYMENT_FAILURE,
    error,
  }),

  updateStripePayment: stripePayments => ({
    type: actions.REQUEST_UPDATE_STRIPE_PAYMENT,
    stripePayments,
  }),

  receiveUpdateStripePayment: data => ({
    type: actions.RECEIVE_UPDATE_STRIPE_PAYMENT,
    data,
  }),

  receiveUpdateStripePaymentFailure: error => ({
    type: actions.RECEIVE_UPDATE_STRIPE_PAYMENT_FAILURE,
    error,
  }),

  createStripeCustomer: params => ({
    type: actions.REQUEST_CREATE_STRIPE_CUSTOMER,
    params,
  }),

  receiveCreateStripeCustomer: data => ({
    type: actions.RECEIVE_CREATE_STRIPE_CUSTOMER,
    data,
  }),

  receiveCreateStripeCustomerFailure: error => ({
    type: actions.RECEIVE_CREATE_STRIPE_CUSTOMER_FAILURE,
    error,
  }),

  updateStripeCustomer: id => ({
    type: actions.REQUEST_UPDATE_STRIPE_CUSTOMER,
    id,
  }),

  receiveUpdateStripeCustomer: data => ({
    type: actions.RECEIVE_UPDATE_STRIPE_CUSTOMER,
    data,
  }),

  receiveUpdateStripeCustonerFailure: error => ({
    type: actions.RECEIVE_UPDATE_STRIPE_CUSTOMER_FAILURE,
    error,
  }),
};
export default actions;
