import actions from './actions';

const initState = {
  data: null,
  error: null,
  isLoading: false,
};

export default function reducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.RECEIVE_FLEET_SCHEDULE: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case actions.RECEIVE_FLEET_SCHEDULE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    }

    case actions.RECEIVE_FLEET_SCHEDULE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: 'receiveFleetScheduleFailure',
        data: [],
      };
    }

    case actions.SAVE_FLEET_SCHEDULE: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case actions.SAVE_FLEET_SCHEDULE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        data: null,
      };
    }

    case actions.SAVE_FLEET_SCHEDULE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }

    case actions.RESET_FLEET_SCHEDULE: {
      return initState;
    }

    default:
      return state;
  }
}
