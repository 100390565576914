import actions from './actions';

const initState = {
  unlockConnector: {},
  reset: {},
};

export default function reducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.REQUEST_UNLOCK_CONNECTOR: {
      return {
        ...state,
        unlockConnector: action.data,
      };
    }

    case actions.REQUEST_RESET: {
      return {
        ...state,
        reset: action.data,
      };
    }

    case actions.REQUEST_REFRESH: {
      return {
        ...state,
      };
    }

    case actions.REQUEST_SETPOINT: {
      return {
        ...state,
      };
    }

    default:
      return state;
  }
}
