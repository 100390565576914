import { all, takeLatest, put, call } from 'redux-saga/effects';
import { fetchEntities, fetchEntity } from './api';
import actions from './actions';

export function* requestFacilities() {
  try {
    const data = yield call(fetchEntities);
    yield put(actions.receiveFacilities(data));
  } catch (e) {
    yield put(actions.receiveFacilitiesFailure(e));
  }
}

export function* requestFacility(params) {
  try {
    const data = yield call(fetchEntity, params.uri);
    yield put(actions.receiveFacility(data));
  } catch (e) {
    yield put(actions.receiveFacilityFailure(e));
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.REQUEST_FACILITIES, requestFacilities),
    yield takeLatest(actions.REQUEST_FACILITY, requestFacility),
  ]);
}
