import actions from './actions';

const initState = {
  payments: [],
  payment: null,
  error: null,
};

export default function reducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.RECEIVE_PAYMENTS: {
      return {
        ...state,
        payments: action.data,
      };
    }

    case actions.RECEIVE_PAYMENTS_FAILURE: {
      return {
        ...state,
        error: 'receivePaymentsFailure',
      };
    }

    case actions.RECEIVE_PAYMENT: {
      return {
        ...state,
        payment: action.data,
      };
    }

    case actions.RECEIVE_PAYMENT_FAILURE: {
      return {
        ...state,
        error: 'receivePaymentFailure',
      };
    }

    case actions.RECEIVE_CREATE_PAYMENT: {
      return {
        ...state,
        payment: action.data,
      };
    }

    case actions.RECEIVE_CREATE_PAYMENT_FAILURE: {
      return {
        ...state,
        error: 'receivePaymentCreateFailure',
      };
    }

    case actions.RECEIVE_UPDATE_PAYMENT: {
      return {
        ...state,
        payment: action.data,
      };
    }

    case actions.RECEIVE_UPDATE_PAYMENT_FAILURE: {
      return {
        ...state,
        error: 'receivePaymentUpdateFailure',
      };
    }

    default:
      return state;
  }
}
