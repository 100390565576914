const BASE_URL = process.env.REACT_APP_GATEWAY_URL;

export const fetchAggregatedResult = async (group, series, id) => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');

  const url = BASE_URL + '/api/a4i/' + group + '/' + id + '/' + series;
  try {
    let response = await fetch(url, {
      method: 'get',
      headers: new Headers({
        Authorization: bearer,
        'Content-Type': 'application/json',
      }),
    });
    const status = await response.status;
    if (status === 200) {
      let data = await response.json();
      return data.results;
    } else {
      throw e;
    }
  } catch (e) {
    throw e;
  }
};

export const fetchTimeSeries = async (group, series, id) => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url = BASE_URL + '/api/a4i/' + group + '/' + id + '/ts/' + series;
  try {
    let response = await fetch(url, {
      method: 'get',
      headers: new Headers({
        Authorization: bearer,
        'Content-Type': 'application/json',
      }),
    });
    const status = await response.status;
    if (status === 200) {
      let data = await response.json();
      return data;
    } else {
      throw e;
    }
  } catch (e) {
    throw e;
  }
};

export const fetchRawAggregation = async (group, series, id) => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url = BASE_URL + '/api/a4i/' + group + '/' + id + '/' + series;
  try {
    let response = await fetch(url, {
      method: 'get',
      headers: new Headers({
        Authorization: bearer,
        'Content-Type': 'application/json',
      }),
    });
    const status = await response.status;
    if (status === 200) {
      let data = await response.json();
      return data;
    } else {
      throw e;
    }
  } catch (e) {
    throw e;
  }
};

export const fetchGeneralData = async (database, series, id) => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url = BASE_URL + '/api/a4i/' + database + '/' + id + '/' + series;
  try {
    let response = await fetch(url, {
      method: 'get',
      headers: new Headers({
        Authorization: bearer,
        'Content-Type': 'application/json',
      }),
    });
    const status = await response.status;
    if (status === 200) {
      let data = await response.json();
      return data;
    } else {
      throw e;
    }
  } catch (e) {
    throw e;
  }
};
