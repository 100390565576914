import React from 'react';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import Routes from './router';
import AppProvider from './AppProvider';
import CookieConsentInit from './containers/CookieConsent/CookieConsentInit';
import 'vanilla-cookieconsent/dist/cookieconsent.css';

const App = () => (
  <Provider store={store}>
    <AppProvider>
      <CookieConsentInit />
      <Routes />
    </AppProvider>
  </Provider>
);

App();

export default App;
