import React from 'react';
import { useSelector } from 'react-redux';
import { DEFAULT_BIA_DOMAIN } from '../../constants';

export const defaultRoles = {
  FREE_TIER: 'free_tier',
  ANALYTICS: 'analytics',
  FACILITIES: 'facilities',
  OPTIMIZATION: 'optimization',
  FORECAST: 'forecast',
  ADMIN: 'admin',
  SALES: 'sales',
  ADVANCED: 'advanced',
  MANAGE_USERS: 'manage_users',
  MANAGE_EVSES: 'manage_evses',
  MAINTAINER: 'maintainer',
  A4I: 'a4i',
  REALTIME: 'realtime',
  IDTAG: 'idtag',
  IDTAG_CREATE: 'idtag_create',
  IDTAG_PRIORITY: 'idtag_priority',
  IDTAG_PLOT: 'idtag_plot',
  ONBOARDING_FACILITY: 'onboarding_facility',
  ONBOARDING_CREATE_FACILITY: 'onboarding_create_facility',
  ONBOARDING_FLEET: 'onboarding_fleet',
  ONBOARDING_USER: 'onboarding_user',
  ONBOARDING_SALE_PRICE: 'onboarding_sale_price',
  ONBOARDING_FACILITY_DELETE: 'onboarding_facility_delete',
  ONBOARDING_OCPP_TOGGLES: 'onboarding_ocpp_toggles',
  ONBOARDING_CONTRACTED_POWER: 'onboarding_contracted_power',
  OPERATIONS: 'operations',
  FLEETS: 'fleets',
  SENSOR_LAST_READING: 'sensor_last_reading',
  EVSE_COMMANDS_EXTENDED: 'evse_commands_extended',
  ONBOARDING_OPTI: 'onboarding_opti',
  RT_REFRESH_TIME_FAST: 'rt_refresh_time_fast',
  OVERRIDE_LIMITS: 'override_limits',
  ALLOW_NEGATIVE_POWER_VALUES: 'allow_negative_power_values',
  RESERVATIONS: 'reservations',
  LANG_SWITCHER: 'lang_switcher',
  ONBOARDING_CREATE_EV_MODEL: 'onboarding_create_ev_model',
  ONBOARDING_CREATE_EVSE_MODEL: 'onboarding_create_evse_model',
  BIA_ADMIN: 'bia_admin',
  MILEAGE: 'mileage',
};

const check = (acceptedRoles, roles) => {
  var result = false;
  acceptedRoles.forEach(
    acceptedRole => (result = result || roles.includes(acceptedRole))
  );
  return result;
};

// Same as checkRoles but in hook.
// ToDo: change checkRoles for this hook
export const useCheckRoles = (requiredRole, bypassForAdmin = true) => {
  const { roles } = useSelector(state => state.Auth);

  if (roles === undefined || roles.length === 0) {
    return false;
  }

  if (roles.includes(defaultRoles.ADMIN) && bypassForAdmin) {
    return true;
  }

  switch (requiredRole) {
    case defaultRoles.FREE_TIER:
      return true;
    case defaultRoles.MANAGE_EVSES:
      return check(
        [
          defaultRoles.ADMIN,
          defaultRoles.MAINTAINER,
          defaultRoles.MANAGE_EVSES,
        ],
        roles
      );
    default:
      return roles.includes(requiredRole);
  }
};

export const checkRoles = requiredRole => {
  const { roles } = useSelector(state => state.Auth);

  if (roles === undefined || roles.length === 0) {
    return false;
  }

  if (roles.includes(defaultRoles.ADMIN)) {
    return true;
  }

  switch (requiredRole) {
    case defaultRoles.FREE_TIER:
      return true;
    case defaultRoles.MANAGE_EVSES:
      return check(
        [
          defaultRoles.ADMIN,
          defaultRoles.MAINTAINER,
          defaultRoles.MANAGE_EVSES,
        ],
        roles
      );
    default:
      return roles.includes(requiredRole);
  }
};

export const hasRoles = () => {
  const { roles } = useSelector(state => state.Auth);
  if (roles === undefined || roles.length === 0) {
    return false;
  }
  return true;
};

// Temporary filter by facility
export const scadaFilter = facilitiesArray => {
  const allowdFacilities = ['bia:facility:28'];
  return !!allowdFacilities.find(facility =>
    facilitiesArray.includes(facility)
  );
};

