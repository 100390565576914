const actions = {
  REQUEST_PAYMENT_OPTIONS: 'REQUEST_PAYMENT_OPTIONS',
  RECEIVE_PAYMENT_OPTIONS: 'RECEIVE_PAYMENT_OPTIONS',
  RECEIVE_PAYMENT_OPTIONS_FAILURE: 'RECEIVE_PAYMENT_OPTIONS_FAILURE',

  REQUEST_CREATE_PAYMENT_OPTION: 'REQUEST_CREATE_PAYMENT_OPTION',
  RECEIVE_CREATE_PAYMENT_OPTION: 'RECEIVE_CREATE_PAYMENT_OPTION',
  RECEIVE_CREATE_PAYMENT_OPTION_FAILURE:
    'RECEIVE_CREATE_PAYMENT_OPTION_FAILURE',

  REQUEST_UPDATE_PAYMENT_OPTION: 'REQUEST_UPDATE_PAYMENT_OPTION',
  RECEIVE_UPDATE_PAYMENT_OPTION: 'RECEIVE_UPDATE_PAYMENT_OPTION',
  RECEIVE_UPDATE_PAYMENT_OPTION_FAILURE:
    'RECEIVE_UPDATE_PAYMENT_OPTIONS_FAILURE',

  REQUEST_PAYMENT_OPTION: 'REQUEST_PAYMENT_OPTION',
  RECEIVE_PAYMENT_OPTION: 'RECEIVE_PAYMENT_OPTION',
  RECEIVE_PAYMENT_OPTION_FAILURE: 'RECEIVE_PAYMENT_OPTION_FAILURE',

  requestPaymentOptions: params => ({
    type: actions.REQUEST_PAYMENT_OPTIONS,
    params,
  }),

  receivePaymentOptions: data => ({
    type: actions.RECEIVE_PAYMENT_OPTIONS,
    data,
  }),

  receivePaymentOptionsFailure: error => ({
    type: actions.RECEIVE_PAYMENT_OPTIONS_FAILURE,
    error,
  }),

  createPaymentOptions: paymentOptions => ({
    type: actions.REQUEST_CREATE_PAYMENT_OPTION,
    paymentOptions,
  }),

  receiveCreatePaymentOptions: data => ({
    type: actions.RECEIVE_CREATE_PAYMENT_OPTION,
    data,
  }),

  createPaymentOptionFailure: error => ({
    type: actions.RECEIVE_CREATE_PAYMENT_OPTION_FAILURE,
    error,
  }),

  updatePaymentOption: paymentOptions => ({
    type: actions.REQUEST_UPDATE_PAYMENT_OPTION,
    paymentOptions,
  }),

  receiveUpdatePaymentOption: data => ({
    type: actions.RECEIVE_UPDATE_PAYMENT_OPTION,
    data,
  }),

  updatePaymentOptionFailure: error => ({
    type: actions.RECEIVE_UPDATE_PAYMENT_OPTION_FAILURE,
    error,
  }),

  requestPaymentOption: id => ({
    type: actions.REQUEST_PAYMENT_OPTION,
    id,
  }),

  receivePaymentOption: data => ({
    type: actions.RECEIVE_PAYMENT_OPTION,
    data,
  }),

  receivePaymentOptionFailure: error => ({
    type: actions.RECEIVE_PAYMENT_OPTION_FAILURE,
    error,
  }),
};
export default actions;
