const actions = {
  REQUEST_ORDERS: 'REQUEST_ORDERS',
  RECEIVE_ORDERS: 'RECEIVE_ORDERS',
  RECEIVE_ORDERS_FAILURE: 'RECEIVE_ORDERS_FAILURE',

  REQUEST_ORDER: 'REQUEST_ORDER',
  RECEIVE_ORDER: 'RECEIVE_ORDER',
  RECEIVE_ORDER_FAILURE: 'RECEIVE_ORDER_FAILURE',

  requestOrders: params => ({
    type: actions.REQUEST_ORDERS,
    params,
  }),

  receiveOrders: data => ({
    type: actions.RECEIVE_ORDERS,
    data,
  }),

  receiveOrdersFailure: error => ({
    type: actions.RECEIVE_ORDERS_FAILURE,
    error,
  }),

  requestOrder: id => ({
    type: actions.REQUEST_ORDER,
    id,
  }),

  receiveOrder: data => ({
    type: actions.RECEIVE_ORDER,
    data,
  }),

  receiveOrderFailure: error => ({
    type: actions.RECEIVE_ORDER_FAILURE,
    error,
  }),
};
export default actions;
