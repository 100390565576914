import actions from './actions';

const initState = {
  generalData: null,
  sessionsPerChargingStation: null,
  energyPerChargingStation: null,
  idleRatioPerChargingStation: null,
  flexibilityPerChargingStation: null,
  sessionsPerUser: null,
  hoursPluggedPerUser: null,
  idleRatioPerUser: null,
  flexibilityPerUser: null,
  hourlyConnectionsAcrossDay: null,
  hourlyDisconnectionsAcrossDay: null,
  hourlySessionsAcrossDay: null,
  powerAcrossDay: null,
  totalEvTimeSeries: null,
  optiPeakTimeSeries: [],
  optiCo2TimeSeries: [],
  optiTouTimeSeries: [],
  optiTouPlusPeakWTimeSeries: [],
  optiTouPlusPeakLCTimeSeries: [],
  optiPeakW: null,
  optiCo2Kg: null,
  optiTouLc: null,
  optiTouPeakW: null,
  optiTouPeakLc: null,
  error: {},
};

export default function reducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.RECEIVE_GENERAL_DATA: {
      return {
        ...state,
        generalData: action.data,
      };
    }

    case actions.RECEIVE_GENERAL_DATA_FAILURE: {
      return {
        ...state,
        error: { ...state.error, receiveGeneralDataFailure: true },
      };
    }

    case actions.RECEIVE_SESSIONS_PER_CHARGING_STATION: {
      return {
        ...state,
        sessionsPerChargingStation: action.data,
        error: {
          ...state.error,
          receiveSessionsPerChargingStationFailure: false,
        },
      };
    }

    case actions.RECEIVE_SESSIONS_PER_CHARGING_STATION_FAILURE: {
      return {
        ...state,
        error: {
          ...state.error,
          receiveSessionsPerChargingStationFailure: true,
        },
      };
    }

    case actions.RECEIVE_ENERGY_PER_CHARGING_STATION: {
      return {
        ...state,
        energyPerChargingStation: action.data,
        error: {
          ...state.error,
          receiveEnergyPerChargingStationFailure: false,
        },
      };
    }

    case actions.RECEIVE_ENERGY_PER_CHARGING_STATION_FAILURE: {
      return {
        ...state,
        error: { ...state.error, receiveEnergyPerChargingStationFailure: true },
      };
    }

    case actions.RECEIVE_IDLE_RATIO_PER_CHARGING_STATION: {
      return {
        ...state,
        idleRatioPerChargingStation: action.data,
        error: {
          ...state.error,
          receiveIdleRatioPerChargingStationFailure: false,
        },
      };
    }

    case actions.RECEIVE_IDLE_RATIO_PER_CHARGING_STATION_FAILURE: {
      return {
        ...state,
        error: {
          ...state.error,
          receiveIdleRatioPerChargingStationFailure: true,
        },
      };
    }

    case actions.RECEIVE_FLEXIBILITY_PER_CHARGING_STATION: {
      return {
        ...state,
        flexibilityPerChargingStation: action.data,
        error: {
          ...state.error,
          receiveFlexibilityPerChargingStationFailure: false,
        },
      };
    }

    case actions.RECEIVE_FLEXIBILITY_PER_CHARGING_STATION_FAILURE: {
      return {
        ...state,
        error: {
          ...state.error,
          receiveFlexibilityPerChargingStationFailure: true,
        },
      };
    }

    case actions.RECEIVE_SESSIONS_PER_USER: {
      return {
        ...state,
        sessionsPerUser: action.data,
        error: { ...state.error, receiveSessionPerUserFailure: false },
      };
    }

    case actions.RECEIVE_SESSIONS_PER_USER_FAILURE: {
      return {
        ...state,
        error: { ...state.error, receiveSessionPerUserFailure: true },
      };
    }

    case actions.RECEIVE_HOURS_PLUGGED_PER_USER: {
      return {
        ...state,
        hoursPluggedPerUser: action.data,
        error: { ...state.error, receiveHoursPluggedPerUserFailure: false },
      };
    }

    case actions.RECEIVE_HOURS_PLUGGED_PER_USER_FAILURE: {
      return {
        ...state,
        error: { ...state.error, receiveHoursPluggedPerUserFailure: true },
      };
    }

    case actions.RECEIVE_IDLE_RATIO_PER_USER: {
      return {
        ...state,
        idleRatioPerUser: action.data,
        error: { ...state.error, receiveIdleRatioPerUserFailure: false },
      };
    }

    case actions.RECEIVE_IDLE_RATIO_PER_USER_FAILURE: {
      return {
        ...state,
        error: { ...state.error, receiveIdleRatioPerUserFailure: true },
      };
    }

    case actions.RECEIVE_FLEXIBILITY_PER_USER: {
      return {
        ...state,
        flexibilityPerUser: action.data,
        error: { ...state.error, receiveFlexybilityPerFailure: false },
      };
    }

    case actions.RECEIVE_FLEXIBILITY_PER_USER_FAILURE: {
      return {
        ...state,
        error: { ...state.error, receiveFlexybilityPerFailure: true },
      };
    }

    case actions.RECEIVE_HOURLY_CONNECTIONS_ACROSS_DAY: {
      return {
        ...state,
        hourlyConnectionsAcrossDay: action.data,
        error: {
          ...state.error,
          receiveHourlyConnectionsAcrossDayFailure: false,
        },
      };
    }

    case actions.RECEIVE_HOURLY_CONNECTIONS_ACROSS_DAY_FAILURE: {
      return {
        ...state,
        error: {
          ...state.error,
          receiveHourlyConnectionsAcrossDayFailure: true,
        },
      };
    }

    case actions.RECEIVE_HOURLY_DISCONNECTIONS_ACROSS_DAY: {
      return {
        ...state,
        hourlyDisconnectionsAcrossDay: action.data,
        error: {
          ...state.error,
          receiveHourlyDiconnectionsAcrossDayFailure: false,
        },
      };
    }

    case actions.RECEIVE_HOURLY_DISCONNECTIONS_ACROSS_DAY_FAILURE: {
      return {
        ...state,
        error: {
          ...state.error,
          receiveHourlyDiconnectionsAcrossDayFailure: true,
        },
      };
    }

    case actions.RECEIVE_HOURLY_SESSIONS_ACROSS_DAY: {
      return {
        ...state,
        hourlySessionsAcrossDay: action.data,
        error: { ...state.error, receiveHourlySessopmsAcrossDayFailure: false },
      };
    }

    case actions.RECEIVE_HOURLY_SESSIONS_ACROSS_DAY_FAILRUE: {
      return {
        ...state,
        error: { ...state.error, receiveHourlySessopmsAcrossDayFailure: true },
      };
    }

    case actions.RECEIVE_POWER_ACROSS_DAY: {
      return {
        ...state,
        powerAcrossDay: action.data,
        error: { ...state.error, receivePowerAcrossDayFailure: false },
      };
    }

    case actions.RECEIVE_POWER_ACROSS_DAY_FAILURE: {
      return {
        ...state,
        error: { ...state.error, receivePowerAcrossDayFailure: true },
      };
    }

    case actions.RECEIVE_TOTAL_EV_TIME_SERIES: {
      return {
        ...state,
        totalEvTimeSeries: action.data,
        error: { ...state.error, receiveTotalEvTimeSeriesFailure: false },
      };
    }

    case actions.RECEIVE_TOTAL_EV_TIME_SERIES_FAILURE: {
      return {
        ...state,
        error: { ...state.error, receiveTotalEvTimeSeriesFailure: true },
      };
    }

    case actions.RECEIVE_OPTI_PEAK_TIME_SERIES: {
      return {
        ...state,
        optiPeakTimeSeries: action.data,
        error: { ...state.error, receiveOptiPeakTimeFailure: false },
      };
    }

    case actions.RECEIVE_OPTI_PEAK_TIME_SERIES_FAILURE: {
      return {
        ...state,
        error: { ...state.error, receiveOptiPeakTimeFailure: true },
      };
    }

    case actions.RECEIVE_OPTI_CO2_TIME_SERIES: {
      return {
        ...state,
        optiCo2TimeSeries: action.data,
        error: { ...state.error, receiveOptiCo2TimeSeriesFailure: false },
      };
    }

    case actions.RECEIVE_OPTI_CO2_TIME_SERIES_FAILURE: {
      return {
        ...state,
        error: { ...state.error, receiveOptiCo2TimeSeriesFailure: true },
      };
    }

    case actions.RECEIVE_OPTI_TOU_TIME_SERIES: {
      return {
        ...state,
        optiTouTimeSeries: action.data,
        error: { ...state.error, receiveOptiTouTimeSeriesFailure: false },
      };
    }

    case actions.RECEIVE_OPTI_TOU_TIME_SERIES_FAIULURE: {
      return {
        ...state,
        error: { ...state.error, receiveOptiTouTimeSeriesFailure: true },
      };
    }

    case actions.RECEIVE_OPTI_TOU_PLUS_PEAK_W_TIME_SERIES: {
      return {
        ...state,
        optiTouPlusPeakWTimeSeries: action.data,
        error: {
          ...state.error,
          receiveOptiTouPlusPeakWTimeSeriesFailure: false,
        },
      };
    }

    case actions.RECEIVE_OPTI_TOU_PLUS_PEAK_W_TIME_SERIES_FAILURE: {
      return {
        ...state,
        error: {
          ...state.error,
          receiveOptiTouPlusPeakWTimeSeriesFailure: true,
        },
      };
    }

    case actions.RECEIVE_OPTI_TOU_PLUS_PEAK_LC_TIME_SERIES: {
      return {
        ...state,
        optiTouPlusPeakLCTimeSeries: action.data,
        error: {
          ...state.error,
          receiveOptiTouPlusPeakLCTimeSeriesFailure: false,
        },
      };
    }

    case actions.RECEIVE_OPTI_TOU_PLUS_PEAK_LC_TIME_SERIES_FAILURE: {
      return {
        ...state,
        error: {
          ...state.error,
          receiveOptiTouPlusPeakLCTimeSeriesFailure: true,
        },
      };
    }

    case actions.RECEIVE_OPTI_PEAK_W: {
      return {
        ...state,
        optiPeakW: action.data,
        error: { ...state.error, receiveOptiPeakWFailure: false },
      };
    }

    case actions.RECEIVE_OPTI_PEAK_W_FAILURE: {
      return {
        ...state,
        error: { ...state.error, receiveOptiPeakWFailure: true },
      };
    }

    case actions.RECEIVE_OPTI_CO2_KG: {
      return {
        ...state,
        optiCo2Kg: action.data,
        error: { ...state.error, receiveOptiCo2KgFailure: false },
      };
    }

    case actions.RECEIVE_OPTI_CO2_KG_FAILURE: {
      return {
        ...state,
        error: { ...state.error, receiveOptiCo2KgFailure: true },
      };
    }

    case actions.RECEIVE_OPTI_TOU_LC: {
      return {
        ...state,
        optiTouLc: action.data,
        error: { ...state.error, receiveOptiTouLcFailure: false },
      };
    }

    case actions.RECEIVE_OPTI_TOU_LC_FAILURE: {
      return {
        ...state,
        error: { ...state.error, receiveOptiTouLcFailure: true },
      };
    }

    case actions.RECEIVE_OPTI_TOU_PEAK_W: {
      return {
        ...state,
        optiTouPeakW: action.data,
        error: { ...state.error, receiveOptiTouPeakWFailure: false },
      };
    }

    case actions.RECEIVE_OPTI_TOU_PEAK_W_FAILURE: {
      return {
        ...state,
        error: { ...state.error, receiveOptiTouPeakWFailure: true },
      };
    }

    case actions.RECEIVE_OPTI_TOU_PEAK_LC: {
      return {
        ...state,
        optiTouPeakLc: action.data,
        error: { ...state.error, receiveOptiTouPeakLcFailure: false },
      };
    }
    case actions.RECEIVE_OPTI_TOU_PEAK_LC_FAILURE: {
      return {
        ...state,
        error: { ...state.error, receiveOptiTouPeakLcFailure: true },
      };
    }

    default:
      return state;
  }
}
