import actions from './actions';

const initState = {
  keycloak: null,
  authenticated: true,
  username: '',
  roles: [],
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case actions.LOGIN_SUCCESS: {
      const username = action.keycloak.tokenParsed
        ? action.keycloak.tokenParsed.preferred_username
        : '';
      const roles = action.keycloak.realmAccess
        ? action.keycloak.realmAccess.roles
        : [];
      return {
        ...state,
        keycloak: action.keycloak,
        authenticated: true,
        username: username,
        roles: roles,
      };
    }

    case actions.LOGOUT:
      return initState;
    default:
      return state;
  }
};

export default authReducer;
