import actions from './actions';

const initState = {
  dateRange: {
    startDate: null,
    endDate: null,
  },
};

export default function reducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.DATE_RANGE_INIT_DATES: {
      const startDateIso = action?.startDate?.toISOString();
      const endDateIso = action?.endDate?.toISOString();

      return {
        ...state,
        dateRange: { startDate: startDateIso, endDate: endDateIso },
      };
    }

    case actions.DATE_RANGE_SET_DATES: {
      const startDateIso = action?.startDate?.toISOString();
      // End date must be untill end of day
      const endDateIso = action?.endDate?.add(1, 'day')?.toISOString();

      localStorage.setItem(
        'dateRangeStart',
        startDateIso
      );
      localStorage.setItem(
        'dateRangeEnd',
        endDateIso
      );

      return {
        ...state,
        dateRange: { startDate: startDateIso, endDate: endDateIso },
      };
    }

    case actions.DATE_RANGE_RESET: {
      localStorage.removeItem('dateRangeStart');
      localStorage.removeItem('dateRangeEnd');

      return {
        ...state,
        dateRange: { startDate: action.startDate, endDate: action.endDate },
      };
    }

    default:
      return { ...state };
  }
}
