const actions = {
  RECEIVE_POWER_PROFILE: 'RECEIVE_POWER_PROFILE',
  RECEIVE_POWER_PROFILE_SUCCESS: 'RECEIVE_POWER_PROFILE_SUCCESS',
  RECEIVE_POWER_PROFILE_FAILURE: 'RECEIVE_POWER_PROFILE_FAILURE',
  CLEAR_POWER_PROFILE: 'CLEAR_POWER_PROFILE',

  receivePowerProfile: data => ({
    type: actions.RECEIVE_POWER_PROFILE,
    payload: data,
  }),

  receivePowerProfileSuccess: data => ({
    type: actions.RECEIVE_POWER_PROFILE_SUCCESS,
    payload: data,
  }),

  receivePowerProfileFailure: error => ({
    type: actions.RECEIVE_POWER_PROFILE_FAILURE,
    error,
  }),

  clearPowerProfile: () => ({
    type: actions.CLEAR_POWER_PROFILE,
  }),
};
export default actions;
