import actions from './actions';

const initState = {
  paymentOptions: [],
  paymentOption: null,
  error: null,
};

export default function reducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.RECEIVE_PAYMENT_OPTION: {
      return {
        ...state,
        paymentOption: action.data,
      };
    }

    case actions.RECEIVE_PAYMENT_OPTIONS_FAILURE: {
      return {
        ...state,
        error: 'receivePaymentOptionFailure',
      };
    }

    case actions.RECEIVE_PAYMENT_OPTIONS: {
      return {
        ...state,
        paymentOptions: action.data,
      };
    }

    case actions.RECEIVE_PAYMENT_OPTIONS_FAILURE: {
      return {
        ...state,
        error: 'receivePaymentOptionsFailure',
      };
    }

    case actions.RECEIVE_CREATE_PAYMENT_OPTION: {
      return {
        ...state,
        paymentOption: action.data,
      };
    }

    case actions.RECEIVE_CREATE_PAYMENT_OPTION_FAILURE: {
      return {
        ...state,
        error: 'receivePaymentOptionCreateFailure',
      };
    }

    case actions.RECEIVE_UPDATE_PAYMENT_OPTION: {
      return {
        ...state,
        paymentOption: action.data,
      };
    }

    case actions.RECEIVE_UPDATE_PAYMENT_OPTION_FAILURE: {
      return {
        ...state,
        error: 'receivePaymentOptionUpdateFailure',
      };
    }

    default:
      return state;
  }
}
