const BASE_URL = process.env.REACT_APP_GATEWAY_URL;

export const fetchFleetSchedule = async facilityUri => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url = `${BASE_URL}/departure-time/facility/${facilityUri}`;
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        Authorization: bearer,
        'content-type': 'application/json',
      }),
    });

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    }

    const res = await response.json();
    return res;
  } catch (e) {
    throw e;
  }
};

export const saveFleetSchedule = async obj => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url = `${BASE_URL}/csv/upload`;

  const formData = new FormData();
  formData.append('file', obj.file);
  formData.append('timezone', obj.timezone);
  formData.append('fleetUri', obj.fleetUri);
  formData.append('facilityUri', obj.facilityUri);

  try {
    let response = await fetch(url, {
      method: 'POST',
      headers: new Headers({
        Authorization: bearer,
      }),
      body: formData,
    });

    if (!response.ok) {
      if (response.status === 400) throw new Error(`Invalid csv format`);
      throw new Error(`Error Uploading csv file!`);
    }

    return [];
  } catch (e) {
    throw e;
  }
};
