import Enlang from './entries/en-US';
import Eslang from './entries/es_ES';
import Trlang from './entries/tr_TR';

const AppLocale = {
  en: Enlang,
  es: Eslang,
  tr: Trlang,
};

export default AppLocale;
