import actions from './actions';

const initState = {
  orders: [],
  order: null,
  error: null,
};

export default function reducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.RECEIVE_ORDERS: {
      return {
        ...state,
        orders: action.data,
      };
    }

    case actions.RECEIVE_ORDERS_FAILURE: {
      return {
        ...state,
        error: 'receiveOrdersFailure',
      };
    }

    case actions.RECEIVE_ORDER: {
      return {
        ...state,
        order: action.data,
      };
    }

    case actions.RECEIVE_ORDER_FAILURE: {
      return {
        ...state,
        error: 'receiveOrderFailure',
      };
    }

    default:
      return state;
  }
}
