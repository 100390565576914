import { all, takeLatest, put, call } from 'redux-saga/effects';
import { fetchEntities, fetchEntity } from './api';
import actions from './actions';

export function* requestPayments(params) {
  try {
    const data = yield call(fetchEntities, params.customerId);
    yield put(actions.receivePayments(data));
  } catch (e) {
    yield put(actions.receivePaymentsFailure, e);
  }
}

export function* requestPayment(params) {
  try {
    const data = yield call(fetchEntity, params.customerId);
    yield put(actions.receivePayment(data));
  } catch (e) {
    yield put(actions.receivePaymentFailure, e);
  }
}

export function* requestCreatePayment(params) {
  try {
    const data = yield call(createEntity, params);
    yield put(actions.createPayment(data));
  } catch (error) {
    yield put(actions.createPaymentFailure, error);
  }
}
export function* requestUpdatePayment(params) {
  try {
    const data = yield call(updateEntity, params);

    yield put(actions.updatePayment(data));
  } catch (error) {
    yield put(actions.updatePaymentFailure, error);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.REQUEST_PAYMENTS, requestPayments),
    yield takeLatest(actions.REQUEST_PAYMENT, requestPayment),
    yield takeLatest(actions.REQUEST_CREATE_PAYMENT, requestCreatePayment),
    yield takeLatest(actions.REQUEST_UPDATE_PAYMENT, requestUpdatePayment),
  ]);
}
