import antdSA from 'antd/lib/locale/tr_TR';
import messages from '../locales/tr_TR.json';
import "dayjs/locale/tr";

const trLang = {
  messages: {
    ...messages,
  },
  antd: antdSA,
  locale: 'tr-TR',
};
export default trLang;
