const actions = {
  DATE_RANGE_SET_DATES: 'DATE_RANGE_SET_DATES',
  DATE_RANGE_INIT_DATES: 'DATE_RANGE_INIT_DATES',
  DATE_RANGE_RESET: 'DATE_RANGE_RESET',

  dateRangeInitDates: (startDate, endDate) => ({
    type: actions.DATE_RANGE_INIT_DATES,
    startDate,
    endDate,
  }),

  dateRangeSetDates: (startDate, endDate) => ({
    type: actions.DATE_RANGE_SET_DATES,
    startDate,
    endDate,
  }),

  dateRangeReset: (startDate, endDate) => ({
    type: actions.DATE_RANGE_RESET,
    startDate,
    endDate,
  }),
};
export default actions;
