import React from 'react';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import themes from '@iso/config/theme/theme.config';
import AppLocale from '@iso/config/translation';
import GlobalStyles from '@iso/assets/styles/globalStyle';

export default function AppProvider({ children }) {
  const { locale } = useSelector(state => state.LanguageSwitcher.language);
  const { themeName } = useSelector(state => state.ThemeSwitcher.changeThemes);
  const currentAppLocale = AppLocale[locale];
  const theme = themes[themeName];

  const customTheme = {
    token: {
      colorPrimary: theme.palette.primary[0],
      colorSuccess: theme.palette.success[0],
      colorWarning: theme.palette.warning[0],
      colorError: theme.palette.error[0],
    },
    components: {
      Typography: {
        colorTex: theme.palette.text[2],
      },
    },
  };

  return (
    <ConfigProvider locale={currentAppLocale.antd} theme={customTheme}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <ThemeProvider theme={theme}>
          <GlobalStyles theme={theme} />
          {children}
        </ThemeProvider>
      </IntlProvider>
    </ConfigProvider>
  );
}
