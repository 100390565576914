import themes from '@iso/config/theme/theme.config';
import { useSelector } from 'react-redux';

const DASHBOARD_BASE_URL = '/dashboard';

const useGoToUrl = () => {
  const { keycloak } = useSelector(state => state.Auth);
  const theme = keycloak?.tokenParsed?.theme;
  const customUrl = theme && themes[theme]?.customUrl;
  const baseUrl = customUrl || DASHBOARD_BASE_URL;

  return {
    baseUrl,
    LEGAL: () => `${baseUrl}/legal`,
    PRIVACY: () => `${baseUrl}/privacy`,
    CHARGING_SESSION: chargingSessionUri =>
      `${baseUrl}/chargingSessions/${chargingSessionUri}`,
    ACTIVE_CHARGING_SESSION: chargingSessionUri =>
      `${baseUrl}/activeChargingSession/${chargingSessionUri}`,
    EVSE: evseUri => `${baseUrl}/evses/${evseUri}`,
    REAL_TIME: () => `${baseUrl}/realtime`,
    ANALYTICS: () => `${baseUrl}/analyticsAndInsights`,
    FACILITY: facilityUri => `${baseUrl}/facilities/${facilityUri}`,
    OPERATIONS: facilityUri => `${baseUrl}/operations/facility/${facilityUri}`,
    IDTAG_REPORT: (facilityUri, tagCode, tagUri) =>
      `${baseUrl}/reports/facility/${facilityUri}/id-tag/${tagCode}?idTagUri=${tagUri}`,
  };
};

export default useGoToUrl;
