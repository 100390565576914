const actions = {
  SET_SELECTED_FLEET: 'SET_SELECTED_FLEET',
  SET_FLEETS: 'SET_FLEETS',

  setFleets: data => ({
    type: actions.SET_FLEETS,
    data,
  }),

  setSelectedFleet: data => ({
    type: actions.SET_SELECTED_FLEET,
    data,
  }),
};

export default actions;
