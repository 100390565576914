import logo from '../../assets/images/logos/Powermanagement_logo_BAR.png';

const BRANDING_COLOR = '#ffd13e';

const PRIMARY = [
  '#0063D2', // 0: Default
  'rgba(0, 99, 210, 0.30)', // 1: Darken 4%
  'rgba(0, 99, 210, 0.15)', // 2: Darken 5%

  // Borrar los demás. Solo 3 colores
  'rgba(68, 130, 255, 0.2)', // 3: Fade 20%
  '#4C8AFF', // 4: Lighten 3%
  'rgba(68, 130, 255, 0.75)', // 5: Fade 75%
  '#6AA8FF', // 6: Lighten 15%
  '#63A1FF', // 7: Lighten 12%
  '#3F7DFA', // 8: Darken 2%
  '#3369e7', // 9: Algolia color
  '#5896FF', // 10: Lighten 8%
  '#2b69e6', // 11:
  '#236cfe', // 12: darken 10%
  '#4d88ff', // 13: Lighten 5%
];
const INFO = [
  '#0063D2', // 0: Info
  'rgba(0, 99, 210, 0.30)', // 1: Info Medium
  'rgba(0, 99, 210, 0.15)', // 1: Info Light
];
const WARNING = [
  '#ffd13e', // 0: Warning
  'rgba(255, 209, 62, 0.30)', // 1: Warning Medium
  'rgba(255, 209, 62, 0.15)', // 2: Warning Light
];
const SUCCESS = [
  '#00B277', // 0: Success
  'rgba(0, 178, 119, 0.30)', // 1: Success Medium
  'rgba(0, 178, 119, 0.15)', // 2: Success Light
];
const ERROR = [
  '#FF6B4A', // 0: Error
  'rgba(255, 107, 74, 0.30)', // 1: Error Medium
  'rgba(255, 107, 74, 0.15)', // 2: Error Light
];
const GRAYSCALE = [
  '#212121', // 0: Black
  '#595959', // 1: Grey Darker
  '#9E9E9E', // 2: Grey Dark
  '#C2C2C2', // 3: Grey
  '#E0E0E0', // 4: Grey Light
  '#F0F0F0', // 5: Grey Lighter
  '#FFFFFF', // 6: Grey Lightest
  '#FFFFFF', // 7: White
];

const theme = {};

theme.palette = {
  primary: PRIMARY,
  info: INFO,
  warning: WARNING,
  success: SUCCESS,
  error: ERROR,
  grayscale: GRAYSCALE,
  secondary: [
    '#aaaaaa', // 0: DarkBlue
    '#f1f3f6', // 1: LightBluish
    '#788195', // 2: LightBlue
    '#E4E6E9', // 3: LightBluish Darken 5%
    '#364d79', // 4:
    '#202739', // 5: DarkBlue Darken 5%
    '#f5f6f8', // 6: LighterBluish
    '#e9ebf1', // 7: DarkBluish
    '#F6F8FB', // 8: LighterBluish Lighten 2%
    '#E9EBEE', // 9: LighterBluish Darken 3%
    '#1a1a1a', // 10: Sidebar submenu select
  ],
  color: [
    '#FEAC01', // 0: Orange
    '#42299a', // 1: Purple
    '#F75D81', // 2: Pink
    '#7ED321', // 3: LimeGreen
    '#39435f', // 4: BlueShade
    '#FFCA28', // 5: Yellow
    '#F2BD1B', // 6: Yellow Darken 5%
    '#3b5998', // 7: Facebook
    '#344e86', // 8: Facebook Darken 5%
    '#dd4b39', // 9: Google Plus
    '#d73925', // 10: Google Plus Darken 5%
    '#e14615', // 11: Auth0
    '#ca3f13', // 12: Auth0
    '#e0364c', // 13: themeColor--AlizarinCrimson
  ],
  text: [
    '#323332', // 0: Heading
    '#595959', // 1: HeadingLight
    'rgba(0, 0, 0, 0.85)', // 2: Text -> before, it was #323332
    '#797979', // 3: TextDark
    '#6a6c6a', // 4: Heading Lighten 22%
    'rgba(0, 0, 0, 0.25)', // 5: Disabled
  ],
  border: [
    '#e9e9e9', // 0: Border
    '#d8d8d8', // 1: BorderDark
    '#ebebeb', // 2: BorderLight
    '#d3d3d3', // 3:
    'rgba(228, 228, 228, 0.65)', // 4:
  ],

  calendar: [
    '#905', // 0:
    '#690', // 1:
    '#a67f59', // 2:
    '#07a', // 3:
    '#dd4a68', // 4:
    '#e90', // 5:
  ],
  bia: [
    '#ffd13e', // 0: BIA Yellow
  ],
  sidebar: [
    'white', // 0 Background
    'white', // 1 LogoBackground
    'rgba(0, 0, 0, 0.1)', // 2 ItemSelected
    'transparent', // 3 ItemBorderColor
    '#595959', // 4 TextColor
    '#323332', // 5 TextColorHover
    'white', // 6 ItemHovered
  ],
  header: [
    '#f3f4f7', // 0 Background
    'rgb(50, 51, 50)', // 1 TextColor
  ],
  footer: [
    'white', // 0 Background
    '#ededed', // 1 BorderColor
    'rgba(0, 0, 0, 0.85)', // 2 TextColor
    '#1890ff', // 3 LinkColor
  ],
  table: [
    '#f8f8f8', // 0 TableHeadBgColor
    '#323332', // 1 TableHeadTextColor
    '#fff', // 2 BgColor
    '#f0f0f0', // 3 BorderColor
  ],
  card: [
    '#ffffff', // 0 CardBgColor
    'rgb(240, 240, 240)', // 1 BorderColor
  ],
  input: [
    '#fff', // 0 BgColor
    'rgb(217, 217, 217)', // 1 BorderColor
  ],
  select: [
    'rgba(0, 0, 0, 0.85)', // 0 Color
    'rgba(0, 0, 0, 0.85)', // 1 ColorHover
    'rgba(0, 0, 0, 0.85)', // 2 ColorSelected
    'transparent', // 0 Bg
    '#E0E0E0', // 1 BgHover
    'rgb(230, 246, 255)', // 2 BgSelected
  ],
  button: [
    '#fff', // 0 BgColor
    'transparent', // 1 BorderColor
    'rgba(0, 0, 0, 0.85)', // 2 TextColor
  ],
  icon: [
    'rgba(0, 0, 0, 0.85)', // 0 Dark
    'rgba(0, 0, 0, 0.25)', // 1 Light
  ],
  background: [
    'rgba(240,242,245,1)', // 0 Background
    'rgba(0, 0, 0, 0.02)', // 1 Light
  ],
  assetStatus: [
    'rgba(0, 99, 210, .15)', // 0 charging (blue)
    'rgba(255, 107, 74, .15)', // 1 Faulted (red)
    'rgba(0, 178, 119, .15)', // 2 available (green)
    'rgba(255, 209, 62, .15)', // 3 unreachable (yellow)
    'rgba(158, 158, 158, .15)', // 4 total (grey)
    'rgba(0, 99, 210, .3)', // 5 charging hover (blue)
    'rgba(255, 107, 74, .3)', // 6 Faulted hover (red)
    'rgba(0, 178, 119, .3)', // 7 available hover (green)
    'rgba(255, 209, 62, .3)', // 8 unreachable hover (yellow)
    'rgba(158, 158, 158, .3)', // 9 total hover (grey)
    'rgba(0, 99, 210, 1)', // 10 charging border (blue)
    'rgba(255, 107, 74, 1)', // 11 Faulted border (red)
    'rgba(0, 178, 119, 1)', // 12 available border (green)
    'rgba(255, 209, 62, 1)', // 13 unreachable border (yellow)
    'rgba(158, 158, 158, 1)', // 14 total border (grey)
  ],
  plots: [PRIMARY[0], BRANDING_COLOR],
  svgColor: [BRANDING_COLOR, "#212121"],
};

theme.fonts = {
  primary: "'Roboto', sans-serif",
  url:
    'https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap',
  pre: 'Consolas, Liberation Mono, Menlo, Courier, monospace',
};

theme.logo = logo;
theme.logoSize = 'auto 30px';

export default theme;
