import actions from './actions';

const initState = {
  chargingSessions: [],
  chargingSessionsFetchCallDone: false,

  chargingSession: null,
  chargingSessionFetchCallDone: true,

  error: null,
};

export default function reducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.GET_CHARGING_SESSIONS: {
      return {
        ...state,
        chargingSessions: action.chargingSessions,
        chargingSessionsFetchCallDone: action.chargingSessionsFetchCallDone,
      };
    }

    case actions.GET_CHARGING_SESSION: {
      return {
        ...state,
        chargingSession: action.chargingSession,
        chargingSessionFetchCallDone: action.chargingSessionFetchCallDone,
      };
    }

    case actions.RECEIVE_CHARGING_SESSIONS: {
      return {
        ...state,
        chargingSessions: action.data,
      };
    }

    case actions.RECEIVE_CHARGING_SESSION: {
      return {
        ...state,
        chargingSession: action.data,
      };
    }
    case actions.RECEIVE_CHARGING_SESSIONS_FAILURE: {
      return {
        ...state,
        error: 'receiveChargingSessionsFailure',
      };
    }

    case actions.RECEIVE_CHARGING_SESSION_FAILURE: {
      return {
        ...state,
        error: 'receiveChargingSessionFailure',
      };
    }

    default:
      return state;
  }
}
