import defaultTheme from './default';
import customTheme from './custom';
import se from './seTheme';
import eiffage from './eiffageTheme';
import sga from './sgaTheme';
import inelbo from './inelboTheme';
import pwrmgmt from './pwgmgmtTheme';
import total from './totalTheme';

const themes = {
  defaultTheme,
  customTheme,
  se,
  eiffage,
  sga,
  inelbo,
  pwrmgmt,
  total,
};

export const themeConfig = {
  topbar: 'defaultTheme',
  sidebar: 'defaultTheme',
  layout: 'defaultTheme',
  theme: 'defaultTheme',
};
export default themes;
