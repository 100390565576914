import actions from './actions';

const initState = {
  customers: [],
  customer: null,
  customerDetails: null,
  validation: {},
  error: null,
};

export default function reducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.RECEIVE_CUSTOMERS: {
      return {
        ...state,
        customers: action.data,
      };
    }

    case actions.RECEIVE_CUSTOMERS_FAILURE: {
      return {
        ...state,
        error: 'receiveCustomersFailure',
      };
    }

    case actions.RECEIVE_CUSTOMER: {
      return {
        ...state,
        customer: action.data,
      };
    }

    case actions.RECEIVE_CUSTOMER_FAILURE: {
      return {
        ...state,
        error: 'receiveCustomerFailure',
      };
    }

    case actions.CREATE_CUSTOMER: {
      return {
        ...state,
        customer: action.data,
      };
    }

    case actions.CREATE_CUSTOMER_FAILURE: {
      return {
        ...state,
        error: 'receiveCustomerCreateFailure',
      };
    }

    case actions.RECEIVE_UPDATE_CUSTOMER: {
      return {
        ...state,
        customer: action.data,
      };
    }

    case actions.RECEIVE_UPDATE_CUSTOMER_FAILURE: {
      return {
        ...state,
        error: 'receiveCustomerUpdateFailure',
      };
    }

    case actions.RECEIVE_CUSTOMER_DETAILS: {
      return {
        ...state,
        customerDetails: action.data,
      };
    }

    case actions.RECEIVE_CUSTOMER_DETAILS_FAILURE: {
      return {
        ...state,
        error: 'receiveCustomerDetailsFailure',
      };
    }

    case actions.RECEIVE_VALIDATE_CUSTOMER: {
      return {
        ...state,
        validation: action.data,
      };
    }

    case actions.RECEIVE_VALIDATE_CUSTOMER_FAILURE: {
      return {
        ...state,
        error: 'EmailAndUsernameInvalid',
      };
    }

    default:
      return state;
  }
}
