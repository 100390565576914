import { takeLatest, put, call, all } from 'redux-saga/effects';
import { fetchFleetSchedule, saveFleetSchedule } from './api';
import actions from './actions';

const delay = time => new Promise(resolve => setTimeout(resolve, time));

const validatePostData = obj => {
  if (!obj.fleetUri) throw new Error('No fleet found');
  if (!obj.facilityUri) throw new Error('No facility found');
  if (!obj.file) throw new Error('No file found');
};

export function* requestFleetSchedule(action) {
  try {
    const data = yield call(fetchFleetSchedule, action.payload);
    yield put(actions.receiveFleetScheduleSuccess(data));
  } catch (e) {
    yield put(actions.receiveFleetScheduleFailure(e));
  }
}

export function* postFleetSchedule(action) {
  try {
    validatePostData(action.payload);
    const data = yield call(saveFleetSchedule, action.payload);
    yield call(delay, 6000);
    yield put(actions.saveFleetScheduleSuccess());
  } catch (e) {
    yield put(actions.saveFleetScheduleFailure(e.message));
  }
}

export default function* operationsSaga() {
  yield all([
    yield takeLatest(actions.RECEIVE_FLEET_SCHEDULE, requestFleetSchedule),
    yield takeLatest(actions.SAVE_FLEET_SCHEDULE, postFleetSchedule),
  ]);
}
