const actions = {
  REQUEST_FACILITIES: 'REQUEST_FACILITIES',
  REQUEST_FACILITIES_SUCCESS: 'REQUEST_FACILITIES_SUCCESS',
  RECEIVE_FACILITIES: 'RECEIVE_FACILITIES',
  RECEIVE_FACILITIES_FAILURE: 'RECEIVE_FACILITIES_FAILURE',

  REQUEST_FACILITY: 'REQUEST_FACILITY',
  RECEIVE_FACILITY: 'RECEIVE_FACILITY',
  RECEIVE_FACILITY_FAILURE: 'RECEIVE_FACILITY_FAILURE',

  SET_SELECTED_FACILITY: 'SET_SELECTED_FACILITY',

  requestFacilities: () => ({
    type: actions.REQUEST_FACILITIES,
  }),

  requestFacilitiesSuccess: () => ({
    type: actions.REQUEST_FACILITIES_SUCCESS,
  }),

  receiveFacilities: data => ({
    type: actions.RECEIVE_FACILITIES,
    data,
  }),

  receiveFacilitiesFailure: error => ({
    type: actions.RECEIVE_FACILITIES_FAILURE,
    error,
  }),

  requestFacility: id => ({
    type: actions.REQUEST_FACILITY,
    id,
  }),

  receiveFacility: data => ({
    type: actions.RECEIVE_FACILITY,
    data,
  }),

  receiveFacilityFailure: error => ({
    type: actions.RECEIVE_FACILITy_FAILURE,
    error,
  }),

  setSelectedFacility: data => ({
    type: actions.SET_SELECTED_FACILITY,
    data,
  }),
};
export default actions;
