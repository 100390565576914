import { all, takeLatest, put, call } from 'redux-saga/effects';
import { updateEntity, createEntity } from './api';
import actions from './actions';

export function* requestCreateStripe(params) {
  try {
    const data = yield call(createEntity, params);
    if (
      params.stripePayements === undefined ||
      params.stripePayements === null
    ) {
      yield put(actions.createStripeCustomer(data));
    } else {
      yield put(actions.createStripePayment(data));
    }
  } catch (error) {
    if (
      params.stripePayements === undefined ||
      params.stripePayements === null
    ) {
      yield put(actions.receiveCreateStripeCustomerFailure, error);
    } else {
      yield put(actions.createStripeCPaymentFailure, error);
    }
  }
}
export function* requestUpdateStripe(params) {
  try {
    const data = yield call(updateEntity, params);
    if (params.stripe.type === 'customer') {
      yield put(actions.updateStripeCustomer(data));
    } else {
      yield put(actions.updateStripePayment(data));
    }
  } catch (error) {
    if (params.stripe.type === 'customer') {
      yield put(actions.receiveUpdateStripeCustonerFailure, error);
    } else {
      yield put(actions.receiveUpdateStripePaymentFailure, error);
    }
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(
      actions.REQUEST_CREATE_STRIPE_CUSTOMER,
      requestCreateStripe
    ),
    yield takeLatest(
      actions.REQUEST_CREATE_STRIPE_PAYMENT,
      requestCreateStripe
    ),
    yield takeLatest(
      actions.RECEIVE_CREATE_STRIPE_PAYMENT_FAILURE,
      requestCreateStripe
    ),
    yield takeLatest(
      actions.RECEIVE_CREATE_STRIPE_CUSTOMER_FAILURE,
      requestCreateStripe
    ),
    yield takeLatest(
      actions.REQUEST_UPDATE_STRIPE_CUSTOMER,
      requestUpdateStripe
    ),
    yield takeLatest(
      actions.REQUEST_UPDATE_STRIPE_PAYMENT,
      requestUpdateStripe
    ),
    yield takeLatest(
      actions.RECEIVE_UPDATE_STRIPE_PAYMENT_FAILURE,
      requestUpdateStripe
    ),
    yield takeLatest(
      actions.RECEIVE_UPDATE_STRIPE_CUSTOMER_FAILURE,
      requestUpdateStripe
    ),
  ]);
}
