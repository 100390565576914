import defaultTheme from './default';
import logo from '../../assets/images/logos/SGA_logo_white.svg';
import font from '../../assets/fonts/centuryGothic/GOTHIC.TTF';

const PRIMARY = [
  '#0063D2', // 0: Default
  'rgba(0, 99, 210, 0.30)', // 1: Darken 4%
  'rgba(0, 99, 210, 0.15)', // 2: Darken 5%

  // Borrar los demás. Solo 3 colores
  'rgba(68, 130, 255, 0.2)', // 3: Fade 20%
  '#4C8AFF', // 4: Lighten 3%
  'rgba(68, 130, 255, 0.75)', // 5: Fade 75%
  '#6AA8FF', // 6: Lighten 15%
  '#63A1FF', // 7: Lighten 12%
  '#3F7DFA', // 8: Darken 2%
  '#3369e7', // 9: Algolia color
  '#5896FF', // 10: Lighten 8%
  '#2b69e6', // 11:
  '#236cfe', // 12: darken 10%
  '#4d88ff', // 13: Lighten 5%
];
const INFO = [
  '#0063D2', // 0: Info
  'rgba(0, 99, 210, 0.30)', // 1: Info Medium
  'rgba(0, 99, 210, 0.15)', // 1: Info Light
];
const WARNING = [
  '#ffd13e', // 0: Warning
  'rgba(255, 209, 62, 0.30)', // 1: Warning Medium
  'rgba(255, 209, 62, 0.15)', // 2: Warning Light
];
const SUCCESS = [
  '#00B277', // 0: Success
  'rgba(0, 178, 119, 0.30)', // 1: Success Medium
  'rgba(0, 178, 119, 0.15)', // 2: Success Light
];
const ERROR = [
  '#FF6B4A', // 0: Error
  'rgba(255, 107, 74, 0.30)', // 1: Error Medium
  'rgba(255, 107, 74, 0.15)', // 2: Error Light
];
const GRAYSCALE = [
  '#212121', // 0: Black
  '#595959', // 1: Grey Darker
  '#9E9E9E', // 2: Grey Dark
  '#C2C2C2', // 3: Grey
  '#E0E0E0', // 4: Grey Light
  '#F0F0F0', // 5: Grey Lighter
  '#FFFFFF', // 6: Grey Lightest
  '#FFFFFF', // 7: White
];

const theme = {
  ...defaultTheme,
  logo,
  logoSize: 'auto 30px',
  customUrl: '/sgasolutions/dashboard',
  palette: {
    primary: PRIMARY,
    info: INFO,
    warning: WARNING,
    success: SUCCESS,
    error: ERROR,
    grayscale: GRAYSCALE,
    text: [
      '#212529', // 0: Heading
      '#212529', // 1: HeadingLight
      '#212529', // 2: Text
      '#212529', // 3: TextDark
      '#212529', // 4: Heading Lighten 22%
      'rgba(255, 255, 255, 0.5)', // 5: Disabled
    ],
    sidebar: [
      '#212529', // 0 Background
      '#212529', // 1 LogoBackground
      'transparent', // 2 ItemSelected
      'transparent', // 3 ItemBorderColor
      '#83857f', // 4 TextColor
      '#fff', // 5 TextColorHover
      'transparent', // 6 ItemHovered
    ],
    header: [
      '#212529', // 0 Background
      '#fff', // 1 TextColor
    ],
    footer: [
      '#212529', // 0 Background
      'transparent', // 1 BorderColor
      '#83857f', // 2 TextColor
      '#fff', // 3 LinkColor
    ],
    table: [
      '#f8f8f8', // 0 TableHeadBgColor
      '#212529', // 1 TableHeadTextColor
      '#fff', // 2 BgColor
      'rgba(255, 255, 255, 0.25)', // 3 BorderColor
    ],
    card: [
      '#fff', // 0 CardBgColor
      '#83857f', // 1 BorderColor
    ],
    input: [
      'transparent', // 0 BgColor
      '#212529', // 1 BorderColor
    ],
    select: [
      'rgba(0, 0, 0, 0.85)', // 0 Color
      'rgba(0, 0, 0, 0.85)', // 1 ColorHover
      'rgba(0, 0, 0, 0.85)', // 2 ColorSelected
      'transparent', // 0 Bg
      '#E0E0E0', // 1 BgHover
      'rgb(230, 246, 255)', // 2 BgSelected
    ],
    button: [
      'transparent', // 0 BgColor
      '#212529', // 1 BorderColor
      '#212529', // 2 TextColor
    ],
    icon: [
      '#212529', // 0 Dark
      '#212529', // 1 Light
    ],
    background: [
      '#f8f8f8', // 0 BackgroundColor
      'rgba(0, 0, 0, 0.02)', // 1 Light
    ],
    assetStatus: [
      'rgba(0, 99, 210, .15)', // 0 charging (blue)
      'rgba(255, 107, 74, .15)', // 1 Faulted (red)
      'rgba(0, 178, 119, .15)', // 2 available (green)
      'rgba(255, 209, 62, .15)', // 3 unreachable (yellow)
      'rgba(158, 158, 158, .15)', // 4 total (grey)
      'rgba(0, 99, 210, .3)', // 5 charging hover (blue)
      'rgba(255, 107, 74, .3)', // 6 Faulted hover (red)
      'rgba(0, 178, 119, .3)', // 7 available hover (green)
      'rgba(255, 209, 62, .3)', // 8 unreachable hover (yellow)
      'rgba(158, 158, 158, .3)', // 9 total hover (grey)
      'rgba(0, 99, 210, 1)', // 10 charging border (blue)
      'rgba(255, 107, 74, 1)', // 11 Faulted border (red)
      'rgba(0, 178, 119, 1)', // 12 available border (green)
      'rgba(255, 209, 62, 1)', // 13 unreachable border (yellow)
      'rgba(158, 158, 158, 1)', // 14 total border (grey)
    ],
    plots: [PRIMARY[0], '#ffd13e'],
    svgColor: ['rgba(131, 133, 127, 0.3)', "#212121"],
  },
  fonts: {
    primary: "'Century Gothic', sans-serif",
    url: font,
  },
};

export default theme;
