const actions = {
  REQUEST_UNLOCK_CONNECTOR: 'REQUEST_UNLOCK_CONNECTOR',
  REQUEST_RESET: 'REQUEST_RESET',
  REQUEST_REFRESH: 'RECEIVE_FACILITY',
  REQUEST_SETPOINT: 'REQUEST_SETPOINT',

  requestUnlockConnector: () => ({
    type: actions.REQUEST_UNLOCK_CONNECTOR,
  }),

  requestReset: () => ({
    type: actions.REQUEST_RESET,
  }),

  requestRefresh: () => ({
    type: actions.REQUEST_REFRESH,
  }),

  requestSetpoint: () => ({
    type: actions.REQUEST_SETPOINT,
  }),
};
export default actions;
