import * as Sentry from "@sentry/react";

const sentryConfig = {
  dsn: "https://46261ae40dc4136e02f4f30fce463f15@o4507406645723136.ingest.de.sentry.io/4507407867183184",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  environment: process.env.NODE_ENV,
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/localhost:3000\/dashboard/, /^https:\/\/dashboard\.biastaging\.com\/dashboard/, /^https:\/\/dashboard\.biapower\.io\/dashboard/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
};

export default sentryConfig;