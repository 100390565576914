import defaultTheme from './default';
import logo from '../../assets/images/logos/Eiffage_logo_w_2.png';

const theme = {
  ...defaultTheme,
  logo,
  palette: {
    text: [
      '#fff', // 0: Heading
      '#fff', // 1: HeadingLight
      '#fff', // 2: Text
      '#fff', // 3: TextDark
      '#fff', // 4: Heading Lighten 22%
      'rgba(255, 255, 255, 0.5)', // 5: Disabled
    ],
    sidebar: [
      '#24272d', // 0 Background
      '#24272d', // 1 LogoBackground
      '#292d33', // 2 ItemSelected
      '#292d33', // 3 ItemBorderColor
      '#fff', // 4 TextColor
      '#fff', // 5 TextColorHover
      '#292d33', // 6 ItemHovered
    ],
    header: [
      '#24272d', // 0 Background
      '#fff', // 1 TextColor
    ],
    footer: [
      '#24272d', // 0 Background
      'transparent', // 1 BorderColor
      '#fff', // 2 TextColor
      '#1890ff', // 3 LinkColor
    ],
    table: [
      '#000', // 0 TableHeadBgColor
      '#fff', // 1 TableHeadTextColor
      '#24272d', // 2 BgColor
      'rgba(255, 255, 255, 0.25)', // 3 BorderColor
    ],
    card: [
      '#24272d', // 0 CardBgColor
      'transparent', // 1 BorderColor
    ],
    input: [
      'transparent', // 0 BgColor
      '#24272d', // 1 BorderColor
    ],
    button: [
      'transparent', // 0 BgColor
      '#505050', // 1 BorderColor
      '#fff', // 2 TextColor
    ],
    icon: [
      '#fff', // 0 Dark
      'rgba(255, 255, 255, 0.25)', // 1 Light
    ],
    background: [
      'rgba(0,0,0,1)', // 0 BackgroundColor
      'rgba(0, 0, 0, 0.02)', // 1 Light
    ],
    assetStatus: [
      'rgba(0, 99, 210, .15)', // 0 charging (blue)
      'rgba(255, 107, 74, .15)', // 1 Faulted (red)
      'rgba(0, 178, 119, .15)', // 2 available (green)
      'rgba(255, 209, 62, .15)', // 3 unreachable (yellow)
      'rgba(158, 158, 158, .15)', // 4 total (grey)
      'rgba(0, 99, 210, .15)', // 5 charging hover (blue)
      'rgba(255, 107, 74, .15)', // 6 Faulted hover (red)
      'rgba(0, 178, 119, .15)', // 7 available hover (green)
      'rgba(255, 209, 62, .15)', // 8 unreachable hover (yellow)
      'rgba(158, 158, 158, .15)', // 9 total hover (grey)
      'rgba(0, 99, 210, 1)', // 10 charging border (blue)
      'rgba(255, 107, 74, 1)', // 11 Faulted border (red)
      'rgba(0, 178, 119, 1)', // 12 available border (green)
      'rgba(255, 209, 62, 1)', // 13 unreachable border (yellow)
      'rgba(158, 158, 158, 1)', // 14 total border (grey)
    ],
  },
  fonts: {
    primary: "'Montserrat', sans-serif",
    url:
      'https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;700&display=swap',
  },
};

export default theme;

/*
import defaultTheme from './default';
import logo from '../../assets/images/logos/eiffage_logo.png';

const theme = {
  ...defaultTheme,
  logo,
  palette: {
    sidebar: [
      '#24272d', // 0 Background
      'white', // 1 LogoBackground
      '#292d33', // 2 ItemSelected
      '#292d33', // 3 ItemBorderColor
      'white', // 4 TextColor
      'white', // 5 TextColorHover
      '#292d33', // 6 ItemHovered
    ],
    footer: [
      'transparent', // 0 Background
      'transparent', // 1 BorderColor
      'rgba(0, 0, 0, 0.85)', // 2 TextColor
      '#1890ff', // 3 LinkColor
    ],
    table: [
      '#24272d', // 0 TableHeadBgColor
      'white', // 1 TableHeadTextColor
    ],
    background: [
      'rgba(240,242,245,1)', // 0 BackgroundColor
    ],
  },
  fonts: {
    primary: "'Montserrat', sans-serif",
    url:
      'https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;700&display=swap',
  },
};

export default theme;
*/
