import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as CookieConsent from 'vanilla-cookieconsent';
import pluginConfig from './CookieConsentConfig';
import useGoToUrl from '../../hooks/useGoToUrl';

const CookieConsentInit = () => {
  const { PRIVACY } = useGoToUrl();
  const { locale } = useSelector(state => state.LanguageSwitcher.language);
  const defaultLocale = locale === 'en' || locale === 'es' ? locale : 'en';

  useEffect(() => {
    CookieConsent.run(pluginConfig(defaultLocale, PRIVACY));
  }, []);

  return null;
};

export default CookieConsentInit;
