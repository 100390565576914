import { handleFetchError } from '@iso/lib/helpers/errorHandle';

const BASE_URL = process.env.REACT_APP_GATEWAY_URL;

/* export const fetchEntities = async facilityId => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  let url = BASE_URL + '/api/evse';
  if (facilityId) {
    url += '/facility/' + facilityId;
  }
  try {
    let response = await fetch(url, {
      method: 'get',
      headers: new Headers({
        Authorization: bearer,
        'Content-Type': 'application/json',
      }),
    });
    let data = await response.json();
    return data;
  } catch (e) {
    throw e;
  }
}; */

export const fetchEntities = async facilityUri => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url = `${BASE_URL}/api/evse/facility/uri/${facilityUri}`;

  try {
    let response = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        Authorization: bearer,
        'Content-Type': 'application/json',
      }),
    });

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    }

    let data = await response.json();
    return data;
  } catch (e) {
    throw e;
  }
};

export const fetchEntity = async evseUri => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url = BASE_URL + '/api/evse/uri/' + evseUri;
  try {
    let response = await fetch(url, {
      method: 'get',
      headers: new Headers({
        Authorization: bearer,
        'Content-Type': 'application/json',
      }),
    });
    if (!response.ok) throw new Error(response.status);
    return response.json();
  } catch (e) {
    throw e;
  }
};

export const fetchEvseStatus = async evseUri => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url = BASE_URL + '/api/manager/status?evseUri=' + evseUri;
  try {
    let response = await fetch(url, {
      method: 'get',
      headers: new Headers({
        Authorization: bearer,
        'Content-Type': 'application/json',
      }),
    });
    if (!response.ok)
      throw new Error(`Fetch evse status error ${response.status}`);
    let data = await response.json();
    return data;
  } catch (e) {
    throw e;
  }
};

export const fetchEvseCompoundStatus = async facilityUriList => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  var url = BASE_URL + '/api/manager/status/compound';

  var i;
  for (i = 0; i < facilityUriList.length; i++) {
    if (i === 0) url += '?facilityUri=' + facilityUriList[i];
    else url += '&facilityUri=' + facilityUriList[i];
  }

  try {
    let response = await fetch(url, {
      method: 'get',
      headers: new Headers({
        Authorization: bearer,
        'Content-Type': 'application/json',
      }),
    });
    let data = await response.json();
    return data;
  } catch (e) {
    throw e;
  }
};

export const resetEvse = async (evseUri, resetType) => {
  if (!evseUri || !resetType) return;
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url =
    BASE_URL +
    '/api/manager/reset?evseUri=' +
    evseUri +
    '&resetType=' +
    resetType;
  try {
    const response = await fetch(url, {
      method: 'post',
      headers: new Headers({
        Authorization: bearer,
        'Content-Type': 'application/json',
      }),
    });
    if (!response.ok)
      throw new Error(`Error reseting the evse ${response.status}`);

    return `Evse reset ${resetType} successfully`;
  } catch (e) {
    throw e;
  }
};

export const unlockConnector = async (evseUri, connectorId) => {
  if (!evseUri || !connectorId) return;
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url =
    BASE_URL +
    '/api/manager/unlock-connector?evse-uri=' +
    evseUri +
    '&connectorId=' +
    connectorId;
  try {
    let response = await fetch(url, {
      method: 'post',
      headers: new Headers({
        Authorization: bearer,
        'Content-Type': 'application/json',
      }),
    });
    if (!response.ok)
      throw new Error(`Error unlocking the connector ${response.status}`);

    return `Connector ${connectorId} unlocked successfully`;
    // let data = await response.json();
    // return data;
  } catch (e) {
    throw e;
  }
};

export const startTransaction = async (idTag, evseUri, connectorId) => {
  if (!idTag || !evseUri || !connectorId) throw new Error('Missing values');
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url = `${BASE_URL}/api/manager/start-transaction?idToken=${idTag}&evseUri=${evseUri}&connectorId=${connectorId}`;

  try {
    let response = await fetch(url, {
      method: 'post',
      headers: new Headers({
        Authorization: bearer,
        'Content-Type': 'application/json',
      }),
    });
    if (!response.ok)
      throw new Error(`Error starting the transaction ${response.status}`);

    return `Transaction started`;
  } catch (e) {
    throw e;
  }
};

export const stopTransaction = async (evseUri, connectorId) => {
  if (!evseUri || !connectorId) throw new Error('Missing values');
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url = `${BASE_URL}/api/manager/stop-transaction?evseUri=${evseUri}&connectorId=${connectorId}`;

  try {
    let response = await fetch(url, {
      method: 'post',
      headers: new Headers({
        Authorization: bearer,
        'Content-Type': 'application/json',
      }),
    });
    if (!response.ok)
      throw new Error(`Error stoping the transaction ${response.status}`);

    return `Transaction stoped`;
  } catch (e) {
    throw e;
  }
};

export const suspendTransaction = async (evseUri, connectorId) => {
  if (!evseUri || !connectorId) throw new Error('Missing values');
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url = `${BASE_URL}/api/manager/suspend-transaction?evseUri=${evseUri}&connectorId=${connectorId}`;

  try {
    let response = await fetch(url, {
      method: 'post',
      headers: new Headers({
        Authorization: bearer,
        'Content-Type': 'application/json',
      }),
    });
    if (!response.ok)
      throw new Error(`Error suspending the transaction ${response.status}`);

    return `Transaction suspended`;
  } catch (e) {
    throw e;
  }
};

export const restart = async (evseUri, connectorId) => {
  if (!evseUri || !connectorId) throw new Error('Missing values');
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url = `${BASE_URL}/api/manager/restart?evseUri=${evseUri}&connectorId=${connectorId}`;

  try {
    let response = await fetch(url, {
      method: 'post',
      headers: new Headers({
        Authorization: bearer,
        'Content-Type': 'application/json',
      }),
    });
    if (!response.ok) throw new Error(`Error restarting ${response.status}`);

    return `Transaction restarted`;
  } catch (e) {
    throw e;
  }
};

export const applySetpoint = async (
  setpoint,
  evseUri,
  connectorId,
  allowOverrideLimits
) => {
  if (setpoint === undefined || !evseUri || !connectorId) throw new Error('Missing values');
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  let url = `${BASE_URL}/api/manager/apply-setpoint?setpoint=${setpoint}&evseUri=${evseUri}&connectorId=${connectorId}`;
  if (allowOverrideLimits) url += '&overrideLimits=true';

  try {
    let response = await fetch(url, {
      method: 'post',
      headers: new Headers({
        Authorization: bearer,
        'Content-Type': 'application/json',
      }),
    });
    if (!response.ok)
      throw new Error(`Error applying a setpoint ${response.status}`);

    return `Setpoint applied`;
  } catch (e) {
    throw e;
  }
};

export const fetchRealtime = async entities => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  var url = BASE_URL + '/api/manager/meter-values/realtime2';
  var i;
  for (i = 0; i < entities.length; i++) {
    if (i === 0) url += '?entityUri=' + entities[i];
    else url += '&entityUri=' + entities[i];
  }

  try {
    let response = await fetch(url, {
      method: 'get',
      headers: new Headers({
        Authorization: bearer,
        'Content-Type': 'application/json',
      }),
    });
    let data = await response.json();
    return data;
  } catch (e) {
    throw e;
  }
};

export const fetchAllEvseModel = async () => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url = `${BASE_URL}/api/evse-model`;

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        Authorization: bearer,
        'content-type': 'application/json',
      }),
    });

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    }

    let data = await response.json();
    return data;
  } catch (e) {
    throw e;
  }
};

export const createCharger = async data => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url = `${BASE_URL}/api/evse`;
  const body = JSON.stringify(data);

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: new Headers({
        Authorization: bearer,
        'content-type': 'application/json',
      }),
      body,
    });

    let data = await response.json();

    handleFetchError(response, data);

    return data;
  } catch (e) {
    throw e;
  }
};

export const deleteCharger = async evseUri => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url = `${BASE_URL}/api/evse/uri/${evseUri}`;

  try {
    const response = await fetch(url, {
      method: 'DELETE',
      headers: new Headers({
        Authorization: bearer,
        'content-type': 'application/json',
      }),
    });

    if (response.status === 204) {
      return 'ok';
    }

    let data = await response.json();

    handleFetchError(response, data);
  } catch (e) {
    throw e;
  }
};

export const updateCharger = async (evseUri, data) => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url = `${BASE_URL}/api/evse/uri/${evseUri}`;
  const body = JSON.stringify(data);

  try {
    const response = await fetch(url, {
      method: 'PUT',
      headers: new Headers({
        Authorization: bearer,
        'content-type': 'application/json',
      }),
      body,
    });

    let data = await response.json();

    handleFetchError(response, data);

    return data;
  } catch (e) {
    throw e;
  }
};

export const updateSocket = async (evseUri, socketUri, _data) => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url = `${BASE_URL}/api/evse/${evseUri}/socket/${socketUri}`;
  const body = JSON.stringify(_data);

  try {
    const response = await fetch(url, {
      method: 'PATCH',
      headers: new Headers({
        Authorization: bearer,
        'content-type': 'application/json',
      }),
      body,
    });

    let data = await response.json();

    handleFetchError(response, data);

    return data;
  } catch (e) {
    throw e;
  }
};

export const deleteSocket = async (evseUri, socketUri) => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url = `${BASE_URL}/api/evse/${evseUri}/socket/${socketUri}`;

  try {
    const response = await fetch(url, {
      method: 'DELETE',
      headers: new Headers({
        Authorization: bearer,
        'content-type': 'application/json',
      }),
    });

    if (response.status === 204) {
      return 'ok';
    }

    let data = await response.json();

    if (data.error) {
      throw data.error;
    }

    handleFetchError(response, data);
  } catch (e) {
    throw e;
  }
};

export const createEvseModel = async data => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url = `${BASE_URL}/api/evse-model`;
  const body = JSON.stringify(data);

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: new Headers({
        Authorization: bearer,
        'content-type': 'application/json',
      }),
      body,
    });

    let data = await response.json();

    handleFetchError(response, data);

    return data;
  } catch (e) {
    throw e;
  }
};

