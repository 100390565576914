import actions from './actions';

const initState = {
  stripePayment: {},
  stripeCustomer: {},
  error: null,
};

export default function reducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.RECEIVE_CREATE_STRIPE_CUSTOMER: {
      return {
        ...state,
        stripeCustomer: action.data,
      };
    }

    case actions.RECEIVE_CREATE_STRIPE_CUSTOMER_FAILURE: {
      return {
        ...state,
        error: 'receiveCreateCustomerFailure',
      };
    }

    case actions.RECEIVE_UPDATE_STRIPE_CUSTOMER: {
      return {
        ...state,
        stripeCustomer: action.data,
      };
    }

    case actions.RECEIVE_UPDATE_STRIPE_CUSTOMER_FAILURE: {
      return {
        ...state,
        error: 'receiveUpdateStripeCustomerFailure',
      };
    }

    case actions.RECEIVE_CREATE_STRIPE_PAYMENT: {
      return {
        ...state,
        stripePayment: action.data,
      };
    }

    case actions.RECEIVE_CREATE_STRIPE_PAYMENT_FAILURE: {
      return {
        ...state,
        error: 'receiveCreateStripePayementFailure',
      };
    }

    case actions.REQUEST_UPDATE_STRIPE_PAYMENT: {
      return {
        ...state,
        stripePayment: action.data,
      };
    }

    case actions.RECEIVE_UPDATE_STRIPE_PAYMENT_FAILURE: {
      return {
        ...state,
        error: 'receiveUpdateStripePayementFailure',
      };
    }

    default:
      return state;
  }
}
