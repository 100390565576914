export const handleFetchError = (response, data) => {
  if (!response.ok) {
    if (data?.violations?.length) {
      const message = data.violations.map(
        el => `Field: ${el.field}, Message: ${el.message}`
      );
      throw message;
    }
    const message = `An error has occured: ${response.status}`;
    throw [message];
  }
};
