const actions = {
  REQUEST_WALLETS: 'REQUEST_WALLETS',
  RECEIVE_WALLETS: 'RECEIVE_WALLETS',
  RECEIVE_WALLETS_FAILURE: 'RECEIVE_WALLETS_FAILURE',

  REQUEST_CREATE_WALLET: 'REQUEST_CREATE_WALLET',
  RECEIVE_CREATE_WALLET: 'RECEIVE_CREATE_WALLET',
  RECEIVE_CREATE_WALLET_FAILURE: 'RECEIVE_CREATE_WALLET_FAILURE',

  REQUEST_UPDATE_WALLET: 'REQUEST_UPDATE_WALLET',
  RECEIVE_UPDATE_WALLET: 'RECEIVE_UPDATE_WALLET',
  RECEIVE_UPDATE_WALLET_FAILURE: 'RECEIVE_UPDATE_WALLET_FAILURE',

  REQUEST_WALLET: 'REQUEST_WALLET',
  RECEIVE_WALLET: 'RECEIVE_WALLET',
  RECEIVE_WALLET_FAILURE: 'RECEIVE_WALLET_FAILURE',

  requestWallets: customerId => ({
    type: actions.REQUEST_WALLETS,
    customerId,
  }),

  receiveWallets: data => ({
    type: actions.RECEIVE_WALLETS,
    data,
  }),

  receiveWalletsFailure: error => ({
    type: actions.RECEIVE_WALLETS_FAILURE,
    error,
  }),

  createWallet: WALLET => ({
    type: actions.REQUEST_CREATE_WALLET,
    WALLET,
  }),

  receiveCreateWallet: data => ({
    type: actions.RECEIVE_CREATE_WALLET,
    data,
  }),

  createWalletFailure: error => ({
    type: actions.RECEIVE_CREATE_WALLET_FAILURE,
    error,
  }),

  updateWallet: (username, money) => ({
    type: actions.REQUEST_UPDATE_WALLET,
    username,
    money,
  }),

  receiveUpdateWallet: data => ({
    type: actions.RECEIVE_UPDATE_WALLET,
    data,
  }),

  updateWalletFailure: error => ({
    type: actions.RECEIVE_UPDATE_WALLET_FAILURE,
    error,
  }),

  requestWallet: id => ({
    type: actions.REQUEST_WALLET,
    id,
  }),

  receiveWallet: data => ({
    type: actions.RECEIVE_WALLET,
    data,
  }),

  receiveWalletFailure: error => ({
    type: actions.RECEIVE_WALLET_FAILURE,
    error,
  }),
};
export default actions;
