import actions from './actions';

const initState = {
  selectedOwner: undefined,
};

export default function reducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.SET_SELECTED_OWNER: {
      return {
        ...state,
        selectedOwner: action.data,
      };
    }

    default:
      return state;
  }
}
