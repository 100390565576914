import defaultTheme from './default';
import logo from '../../assets/images/logos/SE_logo_white.svg';

export default {
  ...defaultTheme,
  primaryColor: 'red',
  logo,
  palette: {
    primary: ['#009e4d', '#3dcd58'],
    secondary: ['#42b4e6', '#55d1fe'],
  },
};
