import { all, takeLatest, put, call } from 'redux-saga/effects';
import { fetchEntities, fetchEntity } from './api';
import actions from './actions';

export function* requestOrders(params) {
  try {
    const data = yield call(
      fetchEntities,
      params.params.customerId,
      params.params.companyId
    );
    yield put(actions.receiveOrders(data));
  } catch (e) {
    yield put(actions.receiveOrdersFailure, e);
  }
}

export function* requestOrder(params) {
  try {
    const data = yield call(fetchEntity, params.id);
    yield put(actions.receiveOrder(data));
  } catch (e) {
    yield put(actions.receiveOrderFailure, e);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.REQUEST_ORDERS, requestOrders),
    yield takeLatest(actions.REQUEST_ORDER, requestOrder),
  ]);
}
