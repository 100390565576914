const actions = {
  REQUEST_GENERAL_DATA: 'REQUEST_GENERAL_DATA',
  RECEIVE_GENERAL_DATA: 'RECEIVE_GENERAL_DATA',
  RECEIVE_GENERAL_DATA_FAILURE: 'RECEIVE_GENERAL_DATA_FAILURE',

  REQUEST_SESSIONS_PER_CHARGING_STATION:
    'REQUEST_SESSIONS_PER_CHARGING_STATION',
  RECEIVE_SESSIONS_PER_CHARGING_STATION:
    'RECEIVE_SESSIONS_PER_CHARGING_STATION',
  RECEIVE_SESSIONS_PER_CHARGING_STATION_FAILURE:
    'RECEIVE_SESSIONS_PER_CHARGING_STATION_FAILURE',

  REQUEST_ENERGY_PER_CHARGING_STATION: 'REQUEST_ENERGY_PER_CHARGING_STATION',
  RECEIVE_ENERGY_PER_CHARGING_STATION: 'RECEIVE_ENERGY_PER_CHARGING_STATION',
  RECEIVE_ENERGY_PER_CHARGING_STATION_FAILURE:
    'RECEIVE_ENERGY_PER_CHARGING_STATION_FAILURE',

  REQUEST_IDLE_RATIO_PER_CHARGING_STATION:
    'REQUEST_IDLE_RATIO_PER_CHARGING_STATION',
  RECEIVE_IDLE_RATIO_PER_CHARGING_STATION:
    'RECEIVE_IDLE_RATIO_PER_CHARGING_STATION',
  RECEIVE_IDLE_RATIO_PER_CHARGING_STATION_FAILURE:
    'RECEIVE_IDLE_RATIO_PER_CHARGING_STATION_FAILURE',

  REQUEST_FLEXIBILITY_PER_CHARGING_STATION:
    'REQUEST_FLEXIBILITY_PER_CHARGING_STATION',
  RECEIVE_FLEXIBILITY_PER_CHARGING_STATION:
    'RECEIVE_FLEXIBILITY_PER_CHARGING_STATION',
  RECEIVE_FLEXIBILITY_PER_CHARGING_STATION_FAILURE:
    'RECEIVE_FLEXIBILITY_PER_CHARGING_STATION_FAILURE',

  REQUEST_SESSIONS_PER_USER: 'REQUEST_SESSIONS_PER_USER',
  RECEIVE_SESSIONS_PER_USER: 'RECEIVE_SESSIONS_PER_USER',
  RECEIVE_SESSIONS_PER_USER_FAILURE: 'RECEIVE_SESSIONS_PER_USER_FAILURE',

  REQUEST_HOURS_PLUGGED_PER_USER: 'REQUEST_HOURS_PLUGGED_PER_USER',
  RECEIVE_HOURS_PLUGGED_PER_USER: 'RECEIVE_HOURS_PLUGGED_PER_USER',
  RECEIVE_HOURS_PLUGGED_PER_USER_FAILURE:
    'RECEIVE_HOURS_PLUGGED_PER_USER_FAILURE',

  REQUEST_IDLE_RATIO_PER_USER: 'REQUEST_IDLE_RATIO_PER_USER',
  RECEIVE_IDLE_RATIO_PER_USER: 'RECEIVE_IDLE_RATIO_PER_USER',
  RECEIVE_IDLE_RATIO_PER_USER_FAILURE: 'RECEIVE_IDLE_RATIO_PER_USER_FAILURE',

  REQUEST_FLEXIBILITY_PER_USER: 'REQUEST_FLEXIBILITY_PER_USER',
  RECEIVE_FLEXIBILITY_PER_USER: 'RECEIVE_FLEXIBILITY_PER_USER',
  RECEIVE_FLEXIBILITY_PER_USER_FAILURE: 'RECEIVE_FLEXIBILITY_PER_USER_FAILURE',

  REQUEST_HOURLY_CONNECTIONS_ACROSS_DAY:
    'REQUEST_HOURLY_CONNECTIONS_ACROSS_DAY',
  RECEIVE_HOURLY_CONNECTIONS_ACROSS_DAY:
    'RECEIVE_HOURLY_CONNECTIONS_ACROSS_DAY',
  RECEIVE_HOURLY_CONNECTIONS_ACROSS_DAY_FAILURE:
    'RECEIVE_HOURLY_CONNECTIONS_ACROSS_DAY_FAILURE',

  REQUEST_HOURLY_DISCONNECTIONS_ACROSS_DAY:
    'REQUEST_HOURLY_DISCONNECTIONS_ACROSS_DAY',
  RECEIVE_HOURLY_DISCONNECTIONS_ACROSS_DAY:
    'RECEIVE_HOURLY_DISCONNECTIONS_ACROSS_DAY',
  RECEIVE_HOURLY_DISCONNECTIONS_ACROSS_DAY_FAILURE:
    'RECEIVE_HOURLY_DISCONNECTIONS_ACROSS_DAY_FAILURE',

  REQUEST_HOURLY_SESSIONS_ACROSS_DAY: 'REQUEST_HOURLY_SESSIONS_ACROSS_DAY',
  RECEIVE_HOURLY_SESSIONS_ACROSS_DAY: 'RECEIVE_HOURLY_SESSIONS_ACROSS_DAY',
  RECEIVE_HOURLY_SESSIONS_ACROSS_DAY_FAILRUE:
    'RECEIVE_HOURLY_SESSIONS_ACROSS_DAY_FAILRUE',

  REQUEST_POWER_ACROSS_DAY: 'REQUEST_POWER_ACROSS_DAY',
  RECEIVE_POWER_ACROSS_DAY: 'RECEIVE_POWER_ACROSS_DAY',
  RECEIVE_POWER_ACROSS_DAY_FAILURE: 'RECEIVE_POWER_ACROSS_DAY_FAILURE',

  REQUEST_TOTAL_EV_TIME_SERIES: 'REQUEST_TOTAL_EV_TIME_SERIES',
  RECEIVE_TOTAL_EV_TIME_SERIES: 'RECEIVE_TOTAL_EV_TIME_SERIES',
  RECEIVE_TOTAL_EV_TIME_SERIES_FAILURE: 'RECEIVE_TOTAL_EV_TIME_SERIES_FAILURE',

  REQUEST_OPTI_PEAK_TIME_SERIES: 'REQUEST_OPTI_PEAK_TIME_SERIES',
  RECEIVE_OPTI_PEAK_TIME_SERIES: 'RECEIVE_OPTI_PEAK_TIME_SERIES',
  RECEIVE_OPTI_PEAK_TIME_SERIES_FAILURE:
    'RECEIVE_OPTI_PEAK_TIME_SERIES_FAILURE',

  REQUEST_OPTI_CO2_TIME_SERIES: 'REQUEST_OPTI_CO2_TIME_SERIES',
  RECEIVE_OPTI_CO2_TIME_SERIES: 'RECEIVE_OPTI_CO2_TIME_SERIES',
  RECEIVE_OPTI_CO2_TIME_SERIES_FAILURE: 'RECEIVE_OPTI_CO2_TIME_SERIES_FAILURE',

  REQUEST_OPTI_TOU_TIME_SERIES: 'REQUEST_OPTI_TOU_TIME_SERIES',
  RECEIVE_OPTI_TOU_TIME_SERIES: 'RECEIVE_OPTI_TOU_TIME_SERIES',
  RECEIVE_OPTI_TOU_TIME_SERIES_FAIULURE:
    'RECEIVE_OPTI_TOU_TIME_SERIES_FAIULURE',

  REQUEST_OPTI_TOU_PLUS_PEAK_W_TIME_SERIES:
    'REQUEST_OPTI_TOU_PLUS_PEAK_W_TIME_SERIES',
  RECEIVE_OPTI_TOU_PLUS_PEAK_W_TIME_SERIES:
    'RECEIVE_OPTI_TOU_PLUS_PEAK_W_TIME_SERIES',
  RECEIVE_OPTI_TOU_PLUS_PEAK_W_TIME_SERIES_FAILURE:
    'RECEIVE_OPTI_TOU_PLUS_PEAK_W_TIME_SERIES_FAILURE',

  REQUEST_OPTI_TOU_PLUS_PEAK_LC_TIME_SERIES:
    'REQUEST_OPTI_TOU_PLUS_PEAK_LC_TIME_SERIES',
  RECEIVE_OPTI_TOU_PLUS_PEAK_LC_TIME_SERIES:
    'RECEIVE_OPTI_TOU_PLUS_PEAK_LC_TIME_SERIES',
  RECEIVE_OPTI_TOU_PLUS_PEAK_LC_TIME_SERIES_FAILURE:
    'RECEIVE_OPTI_TOU_PLUS_PEAK_LC_TIME_SERIES_FAILURE',

  REQUEST_OPTI_PEAK_W: 'REQUEST_OPTI_PEAK_W',
  RECEIVE_OPTI_PEAK_W: 'RECEIVE_OPTI_PEAK_W',
  RECEIVE_OPTI_PEAK_W_FAILURE: 'RECEIVE_OPTI_PEAK_W_FAILURE',

  REQUEST_OPTI_CO2_KG: 'REQUEST_OPTI_CO2_KG',
  RECEIVE_OPTI_CO2_KG: 'RECEIVE_OPTI_CO2_KG',
  RECEIVE_OPTI_CO2_KG_FAILURE: 'RECEIVE_OPTI_CO2_KG_FAILURE',

  REQUEST_OPTI_TOU_LC: 'REQUEST_OPTI_TOU_LC',
  RECEIVE_OPTI_TOU_LC: 'RECEIVE_OPTI_TOU_LC',
  RECEIVE_OPTI_TOU_LC_FAILURE: 'RECEIVE_OPTI_TOU_LC_FAILURE',

  REQUEST_OPTI_TOU_PEAK_W: 'REQUEST_OPTI_TOU_PEAK_W',
  RECEIVE_OPTI_TOU_PEAK_W: 'RECEIVE_OPTI_TOU_PEAK_W',
  RECEIVE_OPTI_TOU_PEAK_W_FAILURE: 'RECEIVE_OPTI_TOU_PEAK_W_FAILURE',

  REQUEST_OPTI_TOU_PEAK_LC: 'REQUEST_OPTI_TOU_PEAK_LC',
  RECEIVE_OPTI_TOU_PEAK_LC: 'RECEIVE_OPTI_TOU_PEAK_LC',
  RECEIVE_OPTI_TOU_PEAK_LC_FAILURE: 'RECEIVE_OPTI_TOU_PEAK_LC_FAILURE',

  requestGeneralData: id => ({
    type: actions.REQUEST_GENERAL_DATA,
    id,
  }),

  receiveGeneralData: data => ({
    type: actions.RECEIVE_GENERAL_DATA,
    data,
  }),
  receiveGeneralDataFailure: error => ({
    type: actions.RECEIVE_GENERAL_DATA_FAILURE,
    error,
  }),

  requestSessionsPerChargingStation: id => ({
    type: actions.REQUEST_SESSIONS_PER_CHARGING_STATION,
    id,
  }),

  receiveSessionsPerChargingStation: data => ({
    type: actions.RECEIVE_SESSIONS_PER_CHARGING_STATION,
    data,
  }),

  receiveSessionsPerChargingStation: data => ({
    type: actions.RECEIVE_SESSIONS_PER_CHARGING_STATION,
    data,
  }),

  receiveSessionsPerChargingStationFailure: error => ({
    type: actions.RECEIVE_SESSIONS_PER_CHARGING_STATION_FAILURE,
    error,
  }),

  requestEnergyPerChargingStation: id => ({
    type: actions.REQUEST_ENERGY_PER_CHARGING_STATION,
    id,
  }),

  receiveEnergyPerChargingStation: data => ({
    type: actions.RECEIVE_ENERGY_PER_CHARGING_STATION,
    data,
  }),

  receiveEnergyPerChargingStationFailure: error => ({
    type: actions.RECEIVE_ENERGY_PER_CHARGING_STATION_FAILURE,
    error,
  }),

  requestIdleRatioPerChargingStation: id => ({
    type: actions.REQUEST_IDLE_RATIO_PER_CHARGING_STATION,
    id,
  }),

  receiveIdleRatioPerChargingStation: data => ({
    type: actions.RECEIVE_IDLE_RATIO_PER_CHARGING_STATION,
    data,
  }),

  receiveIdleRatioPerChargingStationFailure: error => ({
    type: actions.RECEIVE_IDLE_RATIO_PER_CHARGING_STATION_FAILURE,
    error,
  }),

  requestFlexibilityPerChargingStation: id => ({
    type: actions.REQUEST_FLEXIBILITY_PER_CHARGING_STATION,
    id,
  }),

  receiveFlexibilityPerChargingStation: data => ({
    type: actions.RECEIVE_FLEXIBILITY_PER_CHARGING_STATION,
    data,
  }),

  receiveFlexibilityPerChargingStationFailure: error => ({
    type: actions.RECEIVE_FLEXIBILITY_PER_CHARGING_STATION_FAILURE,
    error,
  }),

  requestSessionsPerUser: id => ({
    type: actions.REQUEST_SESSIONS_PER_USER,
    id,
  }),

  receiveSessionsPerUser: data => ({
    type: actions.RECEIVE_SESSIONS_PER_USER,
    data,
  }),

  receiveSessionsPerUserFailure: error => ({
    type: actions.RECEIVE_SESSIONS_PER_USER_FAILURE,
    error,
  }),

  requestHoursPluggedPerUser: id => ({
    type: actions.REQUEST_HOURS_PLUGGED_PER_USER,
    id,
  }),

  receiveHoursPluggedPerUser: data => ({
    type: actions.RECEIVE_HOURS_PLUGGED_PER_USER,
    data,
  }),

  receiveHoursPluggedPerUserFailure: error => ({
    type: actions.RECEIVE_HOURS_PLUGGED_PER_USER_FAILURE,
    error,
  }),

  requestIdleRatioPerUser: id => ({
    type: actions.REQUEST_IDLE_RATIO_PER_USER,
    id,
  }),

  receiveIdleRatioPerUser: data => ({
    type: actions.RECEIVE_IDLE_RATIO_PER_USER,
    data,
  }),

  receiveIdleRatioPerUserFailure: error => ({
    type: actions.RECEIVE_IDLE_RATIO_PER_USER_FAILURE,
    error,
  }),

  requestFlexibilityPerUser: id => ({
    type: actions.REQUEST_FLEXIBILITY_PER_USER,
    id,
  }),

  receiveFlexibilityPerUser: data => ({
    type: actions.RECEIVE_FLEXIBILITY_PER_USER,
    data,
  }),

  receiveFlexibilityPerUserFailure: error => ({
    type: actions.RECEIVE_FLEXIBILITY_PER_USER_FAILURE,
    error,
  }),

  requestHourlyConnectionsAcrossDay: id => ({
    type: actions.REQUEST_HOURLY_CONNECTIONS_ACROSS_DAY,
    id,
  }),

  receiveHourlyConnectionsAcrossDay: data => ({
    type: actions.RECEIVE_HOURLY_CONNECTIONS_ACROSS_DAY,
    data,
  }),

  receiveHourlyConnectionsAcrossDayFailure: error => ({
    type: actions.RECEIVE_HOURLY_CONNECTIONS_ACROSS_DAY_FAILURE,
    error,
  }),

  requestHourlyDisconnectionsAcrossDay: id => ({
    type: actions.REQUEST_HOURLY_DISCONNECTIONS_ACROSS_DAY,
    id,
  }),

  receiveHourlyDisconnectionsAcrossDay: data => ({
    type: actions.RECEIVE_HOURLY_DISCONNECTIONS_ACROSS_DAY,
    data,
  }),

  receiveHourlyDisconnectionsAcrossDayFailure: error => ({
    type: actions.RECEIVE_HOURLY_DISCONNECTIONS_ACROSS_DAY_FAILURE,
    error,
  }),

  requestHourlySessionsAcrossDay: id => ({
    type: actions.REQUEST_HOURLY_SESSIONS_ACROSS_DAY,
    id,
  }),

  receiveHourlySessionsAcrossDay: data => ({
    type: actions.RECEIVE_HOURLY_SESSIONS_ACROSS_DAY,
    data,
  }),

  receiveHourlySessionsAcrossDayFailure: error => ({
    type: actions.RECEIVE_HOURLY_SESSIONS_ACROSS_DAY_FAILRUE,
    error,
  }),

  requestPowerAcrossDay: id => ({
    type: actions.REQUEST_POWER_ACROSS_DAY,
    id,
  }),

  receivePowersAcrossDay: data => ({
    type: actions.RECEIVE_POWER_ACROSS_DAY,
    data,
  }),

  receivePowersAcrossDayFailure: error => ({
    type: actions.RECEIVE_POWER_ACROSS_DAY_FAILURE,
    error,
  }),

  requestTotalEvTimeSeries: id => ({
    type: actions.REQUEST_TOTAL_EV_TIME_SERIES,
    id,
  }),

  receiveTotalEvTimeSeries: data => ({
    type: actions.RECEIVE_TOTAL_EV_TIME_SERIES,
    data,
  }),

  receiveTotalEvTimeSeriesFailure: error => ({
    type: actions.RECEIVE_TOTAL_EV_TIME_SERIES_FAILURE,
    error,
  }),

  requestOptiPeakTimeSeries: id => ({
    type: actions.REQUEST_OPTI_PEAK_TIME_SERIES,
    id,
  }),

  receiveOptiPeakTimeSeries: data => ({
    type: actions.RECEIVE_OPTI_PEAK_TIME_SERIES,
    data,
  }),

  receiveOptiPeakTimeSeriesFailure: error => ({
    type: actions.RECEIVE_OPTI_PEAK_TIME_SERIES_FAILURE,
    error,
  }),

  requestOptiCo2TimeSeries: id => ({
    type: actions.REQUEST_OPTI_CO2_TIME_SERIES,
    id,
  }),

  receiveOptiCo2TimeSeries: data => ({
    type: actions.RECEIVE_OPTI_CO2_TIME_SERIES,
    data,
  }),

  receiveOptiCo2TimeSeriesFailure: error => ({
    type: actions.RECEIVE_OPTI_CO2_TIME_SERIES_FAILURE,
    error,
  }),

  requestOptiTouTimeSeries: id => ({
    type: actions.REQUEST_OPTI_TOU_TIME_SERIES,
    id,
  }),

  receiveOptiTouTimeSeries: data => ({
    type: actions.RECEIVE_OPTI_TOU_TIME_SERIES,
    data,
  }),

  receiveOptiTouTimeSeriesFailure: error => ({
    type: actions.RECEIVE_OPTI_TOU_TIME_SERIES_FAIULURE,
    error,
  }),

  requestOptiTouPlusPeakWTimeSeries: id => ({
    type: actions.REQUEST_OPTI_TOU_PLUS_PEAK_W_TIME_SERIES,
    id,
  }),

  receiveOptiTouPlusPeakWTimeSeries: data => ({
    type: actions.RECEIVE_OPTI_TOU_PLUS_PEAK_W_TIME_SERIES,
    data,
  }),

  receiveOptiTouPlusPeakWTimeSeriesFailure: error => ({
    type: actions.RECEIVE_OPTI_TOU_PLUS_PEAK_W_TIME_SERIES_FAILURE,
    error,
  }),

  requestOptiTouPlusPeakLCTimeSeries: id => ({
    type: actions.REQUEST_OPTI_TOU_PLUS_PEAK_LC_TIME_SERIES,
    id,
  }),

  receiveOptiTouPlusPeakLCTimeSeries: data => ({
    type: actions.RECEIVE_OPTI_TOU_PLUS_PEAK_LC_TIME_SERIES,
    data,
  }),

  receiveOptiTouPlusPeakLCTimeSeriesFailure: error => ({
    type: actions.RECEIVE_OPTI_TOU_PLUS_PEAK_LC_TIME_SERIES_FAILURE,
    error,
  }),

  requestOptiPeakW: id => ({
    type: actions.REQUEST_OPTI_PEAK_W,
    id,
  }),

  receiveOptiPeakW: data => ({
    type: actions.RECEIVE_OPTI_PEAK_W,
    data,
  }),

  receiveOptiPeakWFailure: error => ({
    type: actions.RECEIVE_OPTI_PEAK_W_FAILURE,
    error,
  }),

  requestOptiCo2Kg: id => ({
    type: actions.REQUEST_OPTI_CO2_KG,
    id,
  }),

  receiveOptiCo2Kg: data => ({
    type: actions.RECEIVE_OPTI_CO2_KG,
    data,
  }),

  receiveOptiCo2KgFailure: error => ({
    type: actions.RECEIVE_OPTI_CO2_KG_FAILURE,
    error,
  }),

  requestOptiTouLc: id => ({
    type: actions.REQUEST_OPTI_TOU_LC,
    id,
  }),

  receiveOptiTouLc: data => ({
    type: actions.RECEIVE_OPTI_TOU_LC,
    data,
  }),

  receiveOptiTouLcFailure: error => ({
    type: actions.RECEIVE_OPTI_TOU_LC_FAILURE,
    error,
  }),

  requestOptiTouPeakW: id => ({
    type: actions.REQUEST_OPTI_TOU_PEAK_W,
    id,
  }),

  receiveOptiTouPeakW: data => ({
    type: actions.RECEIVE_OPTI_TOU_PEAK_W,
    data,
  }),

  receiveOptiTouPeakWFailure: error => ({
    type: actions.RECEIVE_OPTI_TOU_PEAK_W_FAILURE,
    error,
  }),

  requestOptiTouPeakLc: id => ({
    type: actions.REQUEST_OPTI_TOU_PEAK_LC,
    id,
  }),

  receiveOptiTouPeakLc: data => ({
    type: actions.RECEIVE_OPTI_TOU_PEAK_LC,
    data,
  }),

  receiveOptiTouPeakLcFailure: error => ({
    type: actions.RECEIVE_OPTI_TOU_PEAK_LC_FAILURE,
    error,
  }),
};
export default actions;
