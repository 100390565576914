import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Keycloak from 'keycloak-js';
// import AdminLayout from "layout/admin/admin.jsx";
import { useDispatch, useSelector } from 'react-redux';
import authAction from '@iso/redux/auth/actions';

const { loginSuccess } = authAction;

const AUTH_URL = process.env.REACT_APP_AUTH_URL;
const AUTH_REALM = process.env.REACT_APP_AUTH_REALM;
const AUTH_CLIENT = process.env.REACT_APP_AUTH_CLIENT;

const Secured = props => {
  if (process.env.REACT_APP_DISABLE_AUTH === true) {
    return <props.component {...props} />;
  }

  const dispatch = useDispatch();
  const keycloak = useSelector(state => state.Auth.keycloak);

  useEffect(() => {
    const keycloak = new Keycloak({
      realm: AUTH_REALM,
      url: AUTH_URL,
      sslRequired: 'external',
      clientId: AUTH_CLIENT,
      publicClient: true,
      confidentialPort: 0,
      onLoad: 'login-required',
    });
    keycloak.onTokenExpired = () => {
      keycloak
        .updateToken(70)
        .then(refreshed => {
          if (refreshed) {
            dispatch(loginSuccess({ ...keycloak }));
            localStorage.setItem('auth-token', keycloak.token);
          }
        })
        .catch(() => {
          console.error('Failed to refresh token');
        });
    };
    keycloak
      .init({
        onLoad: 'login-required',
        // silentCheckSsoRedirectUri: '/silent-check-sso.html',
        checkLoginIframe: false,
      })
      .then(() => {
        dispatch(loginSuccess({ ...keycloak }));
        localStorage.setItem('auth-token', keycloak.token);

        setInterval(() => {
          keycloak
            .updateToken(70)
            .then(refreshed => {
              //.success(refreshed => {
              if (refreshed) {
                dispatch(loginSuccess({ ...keycloak }));
                localStorage.setItem('auth-token', keycloak.token);
              }
            })
            .catch(() => {
              // .error(() => {
              console.error('Failed to refresh token');
            });
        }, 60000);
      })
      .catch((e) => {
        console.error('Failed to log in', e);
      });
  }, []);

  if (keycloak) {
    if (keycloak.authenticated) {
      return <Outlet />;
    } else return <div>Forbidden</div>;
  } else
    return (
      <div>
        <p>Authenticating...</p>
      </div>
    );
};

export default Secured;
