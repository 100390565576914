import { DEFAULT_BIA_DOMAIN } from '@iso/constants';
import actions from './actions';

const initState = {
  domain: DEFAULT_BIA_DOMAIN,
};

export default function(state = initState, action) {
  switch (action.type) {
    case actions.CHANGE_DOMAIN:
      return {
        ...state,
        domain: action.payload,
      };
    default:
      return state;
  }
}
